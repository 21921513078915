<template>
  <router-link :to="'/e/' + event.id">
  <div class="item">
    <div class="item-header">
      <div style="text-align: left;">
        <b>{{event.title}}</b><br>
        <small v-if="org && location">{{org.name}}, {{location.label}}</small>
      </div>
      <div style="text-align: right; white-space: nowrap;">
        <small><b>{{ format(new Date(event.startTime), 'D. MMM') }}</b></small><br>
        <small style="font-size: 0.8em;">{{ format(new Date(event.startTime), 'HH:mm') + '-' + format(new Date(event.endTime), 'HH:mm')}}</small>
      </div>
    </div>
    <p class="line-clamp" style="color: #222222; font-size: small">{{event.description}}</p>
    <small v-if="event.hasRegistration && event.limit">{{event.limit-(event.spotsTaken || 0) + ' ledige plasser'}}</small>
  </div>
  </router-link>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {getOrg} from "@/composables/orgStore";
import {getLocation} from "@/composables/categoryStore";
import {format} from "date-fns";
import {Category, Listing, Organization} from "@/interfaces/types";

export default defineComponent({
  name: "EventCard",
  components: {
  },
  props: {
    event: {
      required: true,
      type: Object as () => Listing,
    },
  },
  setup(props) {
    const org = ref<Organization|null>(null)
    const location = ref<Category|null>(null)

    const updateData = async () => {
      org.value = await getOrg(props.event.orgId)
      location.value = await getLocation(props.event.locationId)
    }

    return { format, org, location, updateData }
  },
  async mounted() {
    await this.updateData()
  },
  watch: {
    event: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateData()
      }
    },
  },
})


</script>

<style scoped>
.item {
  background: #eeeeee;
  text-align: left;
  padding: 1.2em;
  height: 100%;
  width: 100%;
}

.item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.line-clamp {
  width: 100%;
  margin: .5em 0 .5em 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


</style>
