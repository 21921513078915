
import {defineComponent} from 'vue'
import {Chat, Message, Organization} from "@/interfaces/types";
import { format } from "date-fns";
import {authState} from "@/composables/authStore";

export default defineComponent({
  name: "MessageItem",
  props: {
    message: {
      type: Object as () => Message,
      required: true,
    },
    chat: {
      type: Object as () => Chat,
      required: true,
    },
    org: {
      type: Object as () => Organization,
      required: true,
    },
  },
  setup(props) {
    const fromCurrentUser = (authState.currentUserId === props.message.senderId)
    const fromOrg = !(props.chat.authorId === props.message.senderId)
    return {
      fromCurrentUser,
      fromOrg,
      format,
    }
  },
})

