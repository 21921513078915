
import { defineComponent, watch } from 'vue'
import * as auth from "@/composables/authStore";
import * as firebaseui from "firebaseui";
import firebase from 'firebase/app'
import 'firebase/auth'
import {authenticationDebugInfo, authState} from "@/composables/authStore";
import router from "@/admin-app/router";
import Button from "primevue/button/Button";
import Dropdown from "primevue/dropdown/Dropdown";
import {activeOrganizations, currentOrg} from "@/composables/orgStore";

export default defineComponent({
  name: "LoginPage",
  components: {
    Button,
    Dropdown,
  },
  props: {
    redirectTo: {
      type: String,
      default: '/login/loading',
    },
  },
  data() {
    return {
      userId: auth.getUserId(),
      signedIn: auth.isAuthenticated(),
    }
  },
  setup(props) {
    console.log('Redirect to ' + props.redirectTo + ' when signed in.')
    watch(() => authState.currentUserId, (id, lastId) => {
      console.log(`CurrentUserId changed from ${lastId} to ${id}`)
      if (id !== '') {
        console.log(`Automatically signed in with id ${id}`)
        router.push(props.redirectTo)
      }
    })
    return {
      currentOrg,
      activeOrganizations,
      showDebug: authenticationDebugInfo,
    }
  },
  mounted() {
    let authUi = firebaseui.auth.AuthUI.getInstance()
    if (!authUi) authUi = new firebaseui.auth.AuthUI(firebase.auth())
    const authConfig = {
      signInSuccessUrl: this.redirectTo,
      signInOptions: authenticationDebugInfo ?
          [ firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.PhoneAuthProvider.PROVIDER_ID ] :
          [ firebase.auth.EmailAuthProvider.PROVIDER_ID ],
    }
    if (!this.signedIn) {
      authUi.start("#firebaseui-auth-container", authConfig)
    }
  },
  methods: {
    logOut() {
      auth.signOut()
      location.reload()
    },
  },
})
