
import * as eventStore from "@/composables/eventStore";
import {computed, defineComponent, ref, watch} from "vue";
import {Category, Listing} from "@/interfaces/types";
import DataView from "primevue/dataview/DataView";
import * as api from "@/composables/api-open";
import MultiSelect from "primevue/multiselect/MultiSelect";
import EventLoadingView from "@/components/Events/EventLoadingView.vue";
import EventItem from "@/components/Events/EventItem.vue";
import PrivacyPolicyView from "@/components/PrivacyPolicyView.vue";
import Sidebar from "primevue/sidebar/Sidebar";
import EventFilterBar from "@/components/Events/EventFilterBar.vue";
import SelectButton from "primevue/selectbutton/SelectButton";
import ToggleButton from "primevue/togglebutton/ToggleButton";


export default defineComponent({
  name: "EventList",
  components: {
    PrivacyPolicyView,
    EventLoadingView,
    DataView,
    EventItem,
    MultiSelect,
    SelectButton,
    ToggleButton,
    Sidebar,
  },
  props: {
    orgId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortKey: null as null|string,
      sortField: 'startTime',
      sortOptions: [
        {label: 'Last to first', value: '!startTime'},
        {label: 'First to last', value: 'startTime'},
      ],
    }
  },
  setup(props) {
    const showPrivacyModal = ref(false)
    const allEvents = ref(Array<Listing>())
    const filteredEvents = ref(Array<Listing>())
    const allLocations = ref(Array<Category>())
    const selectedLocations = ref(Array<Category>())

    const types = ref(new Array<string>())
    const expired = ref(false)

    const filterEvents = () => {
      const showOnline = types.value.includes('Online')
      const showPhysical = types.value.includes('Fysisk')

      const events = allEvents.value.filter ((event) => {
        const hasExpired = ((event.endTime || Number.MAX_VALUE) < Date.now())
        const isCorrectType = (showOnline && event.isOnline) || (showPhysical && !event.isOnline)
            || (!showOnline && !showPhysical)
        return isCorrectType && (expired.value ? hasExpired : !hasExpired)
      })

      const locations = selectedLocations.value
      if (locations.length > 0) {
        const locationIds = locations.map( (a: Category) => { return a.id })
        filteredEvents.value = events.filter((event) => {
          return (
              locationIds.includes(event.locationId || '')
          )
        })
      } else {
        filteredEvents.value = events
      }
    }

    const loadData = async () => {
      allLocations.value = (await api.getAllLocations()).sort((a, b) => {
        if (a.id === 'landsdekkende') return -1
        return a.label.localeCompare(b.label)});
      const fetchedEvents = (await eventStore.getEventList())
      if (props.orgId) {
        // Filter out all events from other orgs.
        allEvents.value = fetchedEvents.filter ((event) => { return event.orgId === props.orgId })
      } else {
        allEvents.value = fetchedEvents
      }
      filterEvents()
    }

    const searchForLocations = async (event: any) => {
      const query = event.query as string
      allLocations.value = (await api.getAllLocations()).filter((val) => { return val.label.toLowerCase().startsWith(query.toLowerCase()) })
    }

    watch(selectedLocations, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterEvents()
      }
    })

    watch(types, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterEvents()
        console.log('Update filter')
      }
    })

    watch(expired, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterEvents()
        console.log('Update filter')
      }
    })

    const sortOrder = computed(() => {
      return expired.value ? -1 : 1
    })

    return {
      events: filteredEvents,
      allLocations,
      selectedLocations,
      showPrivacyModal,
      types,
      expired,
      sortOrder,
      loadData,
      searchForLocations,
    }
  },
  async mounted() {
    await this.loadData()
  },
  watch: {
    orgId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
  },
})
