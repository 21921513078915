import firebase from 'firebase/app'
import 'firebase/auth'
import {reactive, watch} from 'vue'
import {fetchOrganizationMemberships} from "./api-protected";
import {activeOrganizations, currentOrg} from "@/composables/orgStore";

const firebaseConfig = {
    apiKey: "AIzaSyC2y5NdG3axjeI9byUKet3t0z0RUPGW1SY",
    authDomain: "dili-db.firebaseapp.com",
    databaseURL: "https://dili-db.firebaseio.com",
    projectId: "dili-db",
    storageBucket: "dili-db.appspot.com",
    messagingSenderId: "134396049107",
    appId: "1:134396049107:web:0109e9089ae5b6acf50626",
    measurementId: "G-25C969DVL5",
};

export let authenticationDebugInfo = false

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (process.env.VUE_APP_AUTH_EMULATOR === 'true') {
    console.log('Using local Firebase Emulator Auth. Real accounts unavailable.')
    firebase.auth().useEmulator('http://localhost:9099/')
    // Add the phoneAuth provider if running in the emulator, as the email doesn't work properly.
    authenticationDebugInfo = true
}



// Error handler
const errorHandler = (error: any) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(`Error ${errorCode}: ${errorMessage}`)
    return null
}

// Authentication State
export const authState = reactive( {
    currentUserId: '',
    token: '',
})

export async function waitForAuth(timeoutInterval = 1000) {
    return new Promise<string>((resolve, reject) => {
        if (authState.token !== '') {
            resolve(authState.token)
            return
        }
        const timeout = setTimeout(() => {
            clearTimeout(timeout)
            console.error('TIMEOUT!')
            reject()
        }, timeoutInterval)
        watch(() => authState.token, (token) => {
            if (token !== '') {
                clearTimeout(timeout)
                resolve(token)
            }
        })
    })
}


firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
        const uid = user.uid;
        console.log('Signed in with uid: ' + uid)
        const token = await user.getIdToken()
        // At this point, the watchers of authState is notified.
        authState.currentUserId = uid
        authState.token = token
        const orgs = await fetchOrganizationMemberships()
        activeOrganizations.value = orgs
        if (orgs.length > 0) currentOrg.value = orgs[0]
    } else {
        console.log('Signed out.')
        authState.token = ''
        authState.currentUserId = ''
    }
});

// Signing In

export async function signInEmail(email: string, password: string) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
        .catch(errorHandler)
}

export const getUserId = function (): string|undefined {
    return firebase.auth().currentUser?.uid
}

// Signing out

export async function signOut() {
    return firebase.auth().signOut()
        .catch(errorHandler)
}

// Helper methods

export function isAuthenticated() {
    return (firebase.auth().currentUser !== null)
}
