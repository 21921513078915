<template>
<div class="item">
  <div v-if="review.completed">
    <span><b>{{review.phoneNumber}}</b> har gitt {{convertToStars(review.rating)}}</span>
    <p>{{review.text}}</p>
  </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {Review} from "@/interfaces/types";

export default defineComponent({
  name: "EventReviewItem",
  props: {
    review: {
      type: Object as () => Review,
      required: true,
    },
  },
  setup() {
    const convertToStars = (rating: number) => {
      let stars = ''
      for (let i = 0; i < rating; i++) {
        stars += '⭐️'
      }
      return stars
    }
    return { convertToStars }
  },
})

</script>

<style scoped>
.item {
  background: #F6F7F8;
  text-align: left;
  padding: 1em;
  margin-bottom: 2em;
  width: 100%;
}
</style>