<template>
  <div>
    <ChatListItem  v-for="chat in chats" :chat="chat" :selected="chat === modelValue" :key="chat.id" @click="$emit('update:modelValue', chat)" style="border-bottom: 1px solid rgb(245, 245, 245); cursor: pointer;" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {Chat} from "@/interfaces/types";
import ChatListItem from "@/admin-app/Messaging/ChatListItem.vue";

export default defineComponent({
  name: "ChatListView",
  components: {ChatListItem},
  props: {
    chats: {
      type: Object as () => Array<Chat>,
      required: true,
    },
    modelValue: {
      type: Object as () => Chat|null,
      default: null,
    },
  },
})

</script>

<style scoped>

</style>