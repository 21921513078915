<template>
  <div class="list">
  <div class="item" v-bind:class="{ selectedItem: selected }">
    <span><small>{{event.title}} {{format(new Date(event.startTime), 'DD/MM/YY')}}</small></span>
    <br>
    <small v-if="event.hasRegistration">{{event.spotsTaken || 0}}{{event.limit ? '/'+event.limit : ''}}  deltakere </small>
    <small v-else-if="event.externalRegistrationUrl">Ekstern påmelding</small>
    <small v-else>Ingen påmelding</small>
  </div>
  </div>
</template>

<script lang="ts">
import {Listing} from "@/interfaces/types";
import {defineComponent} from 'vue'
import {format} from "date-fns";


export default defineComponent({
  name: "OrgEventListItem",
  components: {
  },
  props: {
    event: {
      required: true,
      type: Object as () => Listing,
    },
    selected: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { format }
  },
})


</script>

<style scoped>
.list {
  background-color: #e4ebf1;
}
.item {
  background: #FFFFFF;
  text-align: left;
  padding: 1em;
  width: 100%;
}
.selectedItem {
  background: #1EB8CA;
  color: white;
}
</style>
