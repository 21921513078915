
import { defineComponent } from 'vue'
import {Listing, Reservation} from "@/interfaces/types";
import DataTable from "primevue/datatable/DataTable";
import Column from "primevue/column/Column";

export default defineComponent({
  name: "ReservationList",
  components: {
    DataTable,
    Column,
  },
  props: {
    reservations: {
      required: true,
      type: Object as () => Reservation[],
    },
    event: {
      required: true,
      type: Object as () => Listing,
    },
  },
})
