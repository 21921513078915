<template>
  <div style="text-align: left;">
    <b style="font-size: x-large">Opprett arrangement</b>
    <br>
    <p>For å kunne opprette arrangementet trenger vi litt informasjon.</p>
  </div>
  <EventEditForm v-if="event" :org="org" :create-mode="true" :listing="event" @createEvent="createEvent"></EventEditForm>

</template>

<script lang="ts">
import {defineComponent} from 'vue'
import EventEditForm from "@/components/Events/EventEditForm.vue";
import {Listing, Organization} from "@/interfaces/types";

export default defineComponent({
  name: "OrgEventCreate",
  components: {
    EventEditForm,
  },
  props: {
    event: {
      type: Object as () => Listing,
      required: true,
    },
    org: {
      type: Object as () => Organization,
      required: true,
    },
  },
  setup(props, {emit}) {
    const createEvent = async (updatedEvent: Listing) => {
      if (updatedEvent) {
        // TODO: Fix this on server side, client should not be responsible for this.
        const org = props.org
        if (!org) throw Error(`Org with id ${updatedEvent.orgId} not found, cannot create event.`)
        if (org.locationIds.length === 1) {
          updatedEvent.locationId = org.locationIds[0]
        }
        emit('create-event', updatedEvent)
      }
    }
    return { createEvent }
  },
})

</script>

<style scoped>

</style>