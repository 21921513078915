<template>
  <h2 v-if="event">Tilbakemelding på {{event.title}}</h2>
  <form @submit.prevent="sendFeedback" class="p-fluid" style="text-align: left; padding: 2em" >
    <Rating v-model="rating" :stars="5" :cancel="false" class="p-mb-4"/>
    <FormTextArea class="p-field" v-model="reviewText"
                  label="Tilbakemelding"
                  description="Hva synes du om arrangementet?"
                  type="text"
                  outer-style="margin-bottom: 1em"
    />
    <Button label="Send inn tilbakemelding" @click="sendFeedback" />
  </form>
<pre>{{statusMessage}}</pre>

</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {Listing} from "@/interfaces/types";
import {getEvent} from "@/composables/eventStore";
import FormTextArea from "@/components/FormComponents/FormTextArea.vue";
import Rating from "primevue/rating/Rating";
import Button from "primevue/button/Button";
import * as api from "@/composables/api-open";

export default defineComponent({
  name: "EventFeedbackPage",
  components: {
    FormTextArea,
    Rating,
    Button,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    reviewId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const event = ref<Listing|null>(null)
    const reviewText = ref('')
    const rating = ref(0)

    const statusMessage = ref('')

    const loadData = async () => {
      event.value = await getEvent(props.eventId)
    }

    const sendFeedback = async  () => {
      const response = await api.postReview(props.eventId, props.reviewId, rating.value, reviewText.value)

      if (!response) {
        statusMessage.value = 'Noe gikk galt. Vennligst prøv igjen senere.'
      } else if (response.error) {
        statusMessage.value = 'Feilmelding: ' + response.error
      } else if (response.updated) {
        statusMessage.value = 'Tilbakemelding postet.'
      } else if (response.completed) {
        statusMessage.value = 'Tilbakemelding har allerede blitt postet.'
      }
    }

    return { event, reviewText, rating, statusMessage, loadData, sendFeedback }
  },
  mounted() {
    this.loadData()
  },
})

</script>

<style scoped>

</style>