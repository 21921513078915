
import {defineComponent} from 'vue'
import {Chat} from "@/interfaces/types";
import {format} from "date-fns";

export default defineComponent({
  name: "ChatListItem",
  props: {
    chat: {
      type: Object as () => Chat,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { format }
  },
})

