<template>
    <div class="p-mb-2">
      <p><b>{{name}}</b></p>
      <small v-if="description" :id="name+'-input'+'-help'">{{description}}</small>
    </div>
    <div style="margin-bottom: 0.5em">
    <span v-for="option in options" :key="option.value" class="radiobutton">
      <RadioButton
                  :id="'radio'-option.value"
                 :value="option.value"
                 :model-value="modelValue"
                 @update:modelValue="$emit('update:modelValue', $event)"
                 style="margin-right: 0.2em"
      />
      <label :for="'radio'-option.value" v-if="option.label">{{ option.label }}</label>
    </span>
    </div>
    <InlineMessage v-if="validationError">{{validationError}}</InlineMessage>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import RadioButton from "primevue/radiobutton/RadioButton";
import InlineMessage from "primevue/inlinemessage/InlineMessage";

export default defineComponent({
  name: "RadioGroup",
  components: {
    RadioButton,
    InlineMessage,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    validationError: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },

})
</script>

<style scoped>
.radiobutton {
  margin-right: 1em;
}
</style>
