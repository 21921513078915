
import {defineComponent, ref} from "vue";
import {getOrg} from "@/composables/orgStore";
import {getLocation} from "@/composables/categoryStore";
import {format} from "date-fns";
import {Category, Listing, Organization} from "@/interfaces/types";

export default defineComponent({
  name: "EventCard",
  components: {
  },
  props: {
    event: {
      required: true,
      type: Object as () => Listing,
    },
  },
  setup(props) {
    const org = ref<Organization|null>(null)
    const location = ref<Category|null>(null)

    const updateData = async () => {
      org.value = await getOrg(props.event.orgId)
      location.value = await getLocation(props.event.locationId)
    }

    return { format, org, location, updateData }
  },
  async mounted() {
    await this.updateData()
  },
  watch: {
    event: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateData()
      }
    },
  },
})


