
import {defineComponent, ref, PropType, Ref} from 'vue'
import {Category, Organization} from "@/interfaces/types";
import Button from "primevue/button/Button";
import FormInput from "@/components/FormComponents/FormInput.vue";
import FormTextArea from "@/components/FormComponents/FormTextArea.vue";
import AutoComplete from "primevue/autocomplete/AutoComplete";
import * as openAPI from "@/composables/api-open";
import {getLocation, getLocations} from "@/composables/categoryStore";
import InlineMessage from "primevue/inlinemessage/InlineMessage";

export default defineComponent({
  name: "OrgEditProfile",
  components: {
    FormTextArea,
    FormInput,
    Button,
    AutoComplete,
    InlineMessage,
  },
  props: {
    org: {
      type: Object as PropType<Organization>,
      required: true,
    },
  },
  setup(props, {emit}) {
    console.log(props.org)
    const localOrgData = ref<Organization>({...props.org})
    const allLocations = ref(Array<Category>())
    const selectedLocations = ref(Array<Category>())
    const orgNameValidationError = ref('')
    const locationValidationError = ref('')

    const loadData = async () => {
      selectedLocations.value = await getLocations(props.org.locationIds)
    }
    const searchForLocations = async (event: any) => {
      const query = event.query as string
      allLocations.value = (await openAPI.getAllLocations()).filter((val) => { return val.label.toLowerCase().startsWith(query.toLowerCase()) })
    }

    const onSave = async () => {
      if (localOrgData.value.name === '') {
        orgNameValidationError.value = 'Organisasjonens navn kan ikke være tomt.'
      } else {
        orgNameValidationError.value = ''
      }
      const locationIds = selectedLocations.value.map( l => { return l.id })
      if (locationIds) {
        localOrgData.value.locationIds = locationIds
        locationValidationError.value = ''
      } else {
        locationValidationError.value = 'Organisasjonen må høre til et sted.'
      }
      if (orgNameValidationError.value || locationValidationError.value) {
        return
      }
      emit('save-data', localOrgData.value)
    }
    return {
      localOrgData,
      allLocations,
      selectedLocations,
      orgNameValidationError,
      locationValidationError,
      searchForLocations,
      onSave,
      loadData,
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    orgId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
  },
})

