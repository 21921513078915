
import {defineComponent, ref} from 'vue'
import InputText from "primevue/inputtext/InputText";
import Button from "primevue/button/Button";

export default defineComponent({
  name: "MessageInput",
  emits: ['send-message'],
  components: {
    InputText,
    Button,
  },
  setup(props, {emit}) {
    const messageText = ref('')

    const onSend = () => {
      if (messageText.value) {
        emit('send-message', messageText.value)
      }
      messageText.value = ''
    }

    return {
      messageText,
      onSend,
    }
  },
})
