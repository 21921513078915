<template>
  <div class="p-my-3" style="display: flex; align-items: center; text-align: left;">
    <img alt="personer" class="icon" src="@/assets/icons/people.svg"><span>{{event.spotsTaken || 0}}/{{event.limit || 0}} deltakere </span>
  </div>
  <DataTable :value="reservations">
    <Column field="age" header="Alder"></Column>
    <Column field="gender" header="Kjønn"></Column>
    <Column field="name" header="Navn"></Column>
    <Column field="phoneNumber" header="Telefonnummer">
      <template #body="slotProps">
          {{slotProps.data.phoneNumber}}
      </template>
    </Column>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {Listing, Reservation} from "@/interfaces/types";
import DataTable from "primevue/datatable/DataTable";
import Column from "primevue/column/Column";

export default defineComponent({
  name: "ReservationList",
  components: {
    DataTable,
    Column,
  },
  props: {
    reservations: {
      required: true,
      type: Object as () => Reservation[],
    },
    event: {
      required: true,
      type: Object as () => Listing,
    },
  },
})
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
</style>
