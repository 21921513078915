
import {computed, defineComponent, ref} from 'vue'
import {Listing, Organization} from "@/interfaces/types";
import {getOrg} from "@/composables/orgStore";
import {format} from "date-fns";

export default defineComponent({
  name: "EventInfo",
  props: {
    event: {
      type: Object as () => Listing,
      required: true,
    },
  },
  setup(props) {
    const orgData = ref<Organization|null>(null)

    const fetchData = async () => {
      const newData = await getOrg(props.event.orgId)
      if (newData && newData.id !== orgData.value?.id) {
        orgData.value = newData
      }
    }

    const eventUrl = computed(() => {
      const url = props.event.address
      if (!url.startsWith('http')) return 'https://' + url
      return url
    })

    return {
      orgData,
      eventUrl,
      fetchData,
      format,
    }
  },
  mounted() {
    this.fetchData()
  },
})

