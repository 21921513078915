<template>
    <div v-if="submitState.status" class="p-fluid">
      <br>
      <div style="margin-top: 6em">
        <span v-if="submitState.state === 'error'" class="pi pi-exclamation-triangle" style="font-size: 4rem"></span>
        <span v-if="submitState.state === 'submitted'" class="pi pi-check-circle" style="font-size: 4rem"></span>
        <ProgressBar v-if="submitState.state === 'submitting'" mode="indeterminate" style="margin: 2em; height: 1em"/>
      </div>
      <h3 >{{submitState.status}}</h3>
      <Button v-if="submitState.state !== 'submitting'" style="margin-top: 2em" label="Ok" @click="$emit('close')" />
    </div>
    <slot v-else>
    </slot>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import ProgressBar from "primevue/progressbar/ProgressBar";
import Button from "primevue/button/Button";

export interface SubmitState {
  state: string;
  status: string;
}

export default defineComponent({
  name: "SubmitFormView",
  emits: ['close'],
  components: {
    ProgressBar,
    Button,
  },
  props: {
    submitState: {
      type: Object as () => SubmitState,
      required: true,
    },
  },
})

</script>

<style scoped>

</style>
