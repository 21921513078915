
import {defineComponent} from 'vue'
import {Chat} from "@/interfaces/types";
import ChatListItem from "@/admin-app/Messaging/ChatListItem.vue";

export default defineComponent({
  name: "ChatListView",
  components: {ChatListItem},
  props: {
    chats: {
      type: Object as () => Array<Chat>,
      required: true,
    },
    modelValue: {
      type: Object as () => Chat|null,
      default: null,
    },
  },
})

