
import {defineComponent, ref, watch} from 'vue'
import {Chat, Message, Organization} from "@/interfaces/types";
import * as api from "@/composables/api-protected";
import ChatListView from "@/admin-app/Messaging/ChatListView.vue";
import MessageInput from "@/admin-app/Messaging/MessageInput.vue";
import {getOrg} from "@/composables/orgStore";
import MessageListView from "@/admin-app/Messaging/MessageListView.vue";

export default defineComponent({
  name: "ChatPage",
  components: {
    MessageListView,
    ChatListView,
    MessageInput,
  },
  props: {
    orgId: {
      type: String,
      required: true,
    },
    chatId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const chats = ref(new Array<Chat>())
    const selectedChat = ref<Chat|null>(null)
    const messages = ref(new Array<Message>())
    const org = ref<Organization|null>(null)

    const fetchChats = async () => {
      const chatsData = await api.getChats(props.orgId)
      chats.value = chatsData.sort((a, b) => {return b.timestamp - a.timestamp})
      const selectedChatId = props.chatId as string|undefined

      if (selectedChatId) {
        const matches = chatsData.filter((chat) => {
          return chat.id === selectedChatId
        })
        if (matches.length === 1) {
          selectedChat.value = matches[0]
        }
      }
    }

    const fetchMessages = async () => {
      const chat = selectedChat.value
      if (!chat) return
      const orgId = props.orgId

      const messageData = await api.getMessages(orgId, chat.eventId, chat.id)
      messageData.sort( (a, b) => { return a.timestamp - b.timestamp })
      messages.value = messageData
    }

    const fetchData = async () => {
      org.value = await getOrg(props.orgId)
      await fetchChats()
    }

    const sendMessage = (text: string) => {
      const chat = selectedChat.value
      if (chat && text) {
        api.sendMessage(props.orgId, chat, text).then(async () => {
          await fetchMessages()
          chat.title = text
          chat.timestamp = Date.now()
        })
      }
    }

    watch(selectedChat, (newChat, oldChat) => {
      if (newChat !== oldChat) {
        fetchMessages()
      }
    })

    return {
      chats,
      messages,
      selectedChat,
      org,
      fetchData,
      fetchChats,
      fetchMessages,
      sendMessage }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    orgId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData()
      }
    },
  },
})


