import {Chat, Listing, Message, Organization, Reservation, Review} from "@/interfaces/types";
import axios from 'axios'
import {waitForAuth} from "./authStore";

console.log('Using endpoint: ' + process.env.VUE_APP_ENDPOINT_URL)
const RESTEndpoint = process.env.VUE_APP_ENDPOINT_URL

const getHeaders = () => {
    const headers: {[index: string]: string} = {
        'Content-Type': 'application/json',

    }
    return headers
}

async function executeAPIPost(path: string, data: any, requiresAuth: boolean): Promise<any> {
    const headers = getHeaders()
    if (requiresAuth) {
        // Needs to wait for the authentication token to be set. This does not have a timeout yet.
        try {
        const token = await waitForAuth(1000)
        headers['Authorization'] = `Bearer ${token}`
        } catch (error) {
            return Promise.reject('UNAUTHENTICATED')
        }
    }
    return axios.post(RESTEndpoint + path, { "data" : data }, { headers: headers })
        .then(function (response) {
            const result = response.data
            const error = result.error
            if (error) {
                console.error(`Error executing ${path}: ${error}`)
                return {
                    error: error,
                }
            }
            return result
        }).catch(function (error) {
            console.error(error)
            return {
                error: error.response.data,
            }
        });
}

const executeAPIGet = async function (path: string, requiresAuth: boolean): Promise<any> {
    const headers = getHeaders()
    if (requiresAuth) {
        // Needs to wait for the authentication token to be set. This does not have a timeout yet.
        try {
            const token = await waitForAuth(1000)
            headers['Authorization'] = `Bearer ${token}`
        } catch (error) {
            console.error('Attempting to execute API call that requires auth without being authenticated!')
            return Promise.reject('UNAUTHENTICATED')
        }
    }

    return axios.get(RESTEndpoint + path, { headers: headers })
        .then(function (response) {
            const result = response.data
            const error = result.error
            if (error) {
                console.error(`Error executing ${path}: ${error}`)
                return null
            }
            return result
        }).catch(function (error) {
            console.error('Error executing REST call:')
            console.error(error)
            return null
        });
}


export const createEvent = async function (event: Listing) {
    return executeAPIPost('event/create', { "event": event }, true).then((result) => {
        if (!result) return ''
        const error = result.error as string|undefined
        if (error) console.error(error)

        const success = result.success as boolean
        if (success) {
            const eventId = result.eventId as string
            console.log('Created event with id: ' + eventId)
            return eventId
        } else {
            return ''
        }
    })
}

export const updateEvent = async function (event: Listing) {

    return executeAPIPost('event/update', { "eventId": event.id, "event": event }, true).then((result) => {
        if (!result) return null
        const error = result.error as string | undefined
        if (error) console.error(error)
        const success = result.success as boolean
        if (success) {
            const eventId = result.eventId as string
            console.log('Updated event with id: ' + eventId)
            return eventId
        } else {
            return ''
        }
    })
}

export async function updateOrganization(orgId: string, org: Organization) {
    return executeAPIPost('org/update', { "orgId": orgId, "orgData": org }, true).then((result) => {
        if (!result) return { success: false, errorMessage: null }
        const error = result.error
        if (error) {
            console.error(`Error updating organization with id ${orgId}: ${error}`)
            return { success: false, errorMessage: `${error}` }
        }
        return { success: true, errorMessage: null }
    })
}

export async function fetchOrganizationMemberships() {

    return executeAPIGet('user/orgs', true).then((result) => {
        if (!result) return Array<Organization>()
        const error = result.error
        if (error) {
            console.error(`Error getting memberships for current user: ${error}`)
            return Array<Organization>()
        }
        return result.orgs as Array<Organization>
    })
}

export async function getReservations(eventId: string) {
    return executeAPIGet(`event/${eventId}/reservations`, true).then((result) => {
        if (!result) return []
        return result.reservations as Array<Reservation>
    })
}

export async function getChats(orgId: string) {
    return executeAPIGet(`org/${orgId}/chats`, true).then((result) => {
        if (!result) return Array<Chat>()
        return result.chats as Array<Chat>
    })
}

export async function getMessages(orgId: string, eventId: string, chatId: string): Promise<Message[]> {
    return executeAPIGet(`org/${orgId}/chats/${chatId}/messages`, true).then((result) => {
        if (!result) return []
        return result.messages as Array<Message>
    })
}

export async function sendMessage(orgId: string, chat: Chat, text: string) {
    return executeAPIPost('org/replyMessage', { orgId: orgId, eventId: chat.eventId, chatId: chat.id, message: text }, true)
}

export async function requestReviews(eventId: string) {
    return executeAPIPost(`event/${eventId}/requestFeedback`, { }, true)
}

export async function getReviews(eventId: string): Promise<Array<Review>> {
    return executeAPIGet(`event/${eventId}/feedback`, true).then((result) => {
        if (!result) return []
        return result.reviews as Array<Review>
    })
}