
import {defineComponent, reactive, ref, watch, computed} from 'vue'
import {Reservation} from "@/interfaces/types"
import Button from "primevue/button/Button"
import RadioGroup from "@/components/FormComponents/RadioGroup.vue";
import FormInput from "@/components/FormComponents/FormInput.vue";
import {validatePhoneNumber} from "@/interfaces/validators";

export default defineComponent({
  name: "RegistrationForm",
  components: {
    FormInput,
    RadioGroup,
    Button,
  },
  data() {
    return {
      genderOptions: [
        { value: "mann",  label: "Mann" },
        { value: "kvinne", label: "Kvinne" },
        { value: "annet", label: "Annet" },
      ],
      ageOptions: [
        { value: 13,  label: "13-19" },
        { value: 20, label: "20-35" },
        { value: 36, label: "36-49" },
        { value: 50, label: "50+" },
      ],
    }
  },
  setup(props, {emit}) {
    const phoneNumber = ref('')
    const age = ref(0)
    const gender = ref('')
    const name = ref('')
    const validationErrors = reactive({
      phone: '',
      age: '',
      gender: '',
    })

    const validateForm = (field?: string) => {

      if (!field || field === 'phone') {
        validationErrors.phone = validatePhoneNumber(phoneNumber.value)
      }

      if (!field || field === 'age') {
        if (age.value <= 0) {
          validationErrors.age = "Ugyldig alder"
        } else {
          validationErrors.age = ''
        }
      }
      if (!field || field === 'gender') {
        if (gender.value.length <= 0) {
          validationErrors.gender = "Kjønn mangler"
        } else {
          validationErrors.gender = ''
        }
      }
      return !(validationErrors.phone || validationErrors.age || validationErrors.gender)
    }

    watch(gender, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        validateForm('gender')
      }
    })

    watch(age, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        validateForm('age')
      }
    })

    watch(phoneNumber, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        if (validationErrors.phone) {
          // Skips the validation errors while typing the number for the first time.
          validateForm('phone')
        }
      }
    })

    const onSubmit = () => {
      const isValid = validateForm()
      if (isValid) {
        const reservation: Reservation = {
          attending: true,
          phoneNumber: phoneNumber.value,
          gender: gender.value,
          age: age.value,
        }
        if (name.value !== '') {
          reservation.name = name.value
        }
        emit('register', reservation)
        return
      }
    }

    const isValid = computed(() => {
      return !(validationErrors.phone || validationErrors.age || validationErrors.gender)
    })

    return {
      phoneNumber,
      age,
      gender,
      name,
      validationErrors,
      isValid,
      validateForm,
      onSubmit,
    }
  },
})
