export function validatePhoneNumber(phoneNumber: string) {
    if (phoneNumber.length === 0) {
        return  "Telefonnummer mangler"
    } else if (phoneNumber.length !== 8) {
        return "Telefonnummeret må være 8 siffer"
    } else if (/[a-zA-Z]/g.test(phoneNumber)) {
        return "Telefonnummeret inneholder bokstaver"
    } else {
        return ''
    }
}
