<template>
  <div v-if="org" style="padding-left: 5em">
    <div v-if="isEditing" class="p-mx-6 p-my-4" style="max-width: 40em">
      <OrgEditProfile :org="org" @saveData="onOrgSave"/>
    </div>
    <div v-else>
      <OrgInfo class="p-mx-6 org-info" :org="org" :locations="locations"/>
      <div class="p-mt-6 p-mx-6 org-info">
      <Button label="Endre" @click="isEditing = true"/>
      </div>
    </div>
  </div>
  <div v-else>
    <h2>Laster...</h2>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {Category, Organization} from "@/interfaces/types";
import {getOrg} from "@/composables/orgStore";
import OrgInfo from "@/admin-app/Organizations/OrgInfo.vue";
import Button from "primevue/button/Button";
import OrgEditProfile from "@/admin-app/Organizations/OrgEditProfile.vue";
import * as api from "@/composables/api-protected";
import {updateOrgData} from "@/composables/util";
import {getLocation, getLocations} from "@/composables/categoryStore";

export default defineComponent({
  name: "OrgProfilePage",
  components: {
    Button,
    OrgInfo,
    OrgEditProfile,
  },
  props: {
    orgId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const org = ref<Organization|null>(null)
    const locations = ref(Array<Category>())

    const isEditing = ref(false)

    const loadData = async () => {
      const orgData = await getOrg(props.orgId)
      org.value = orgData
      if (orgData) {
        locations.value = (await getLocations(orgData.locationIds))
      }
    }

    const onOrgSave = async (updatedOrg: Organization) => {
      isEditing.value = false
      const result = await api.updateOrganization(props.orgId, updatedOrg)
      if (result.success) {
        if (updatedOrg.locationIds !== locations.value.map( l => l.id )) {
          locations.value = await getLocations(updatedOrg.locationIds)
        }
        updateOrgData(org.value, updatedOrg)
      }
    }

    return {
      org,
      locations,
      isEditing,
      loadData,
      onOrgSave,
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    orgId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
  },

})

</script>

<style scoped>
.org-info {
  text-align: left;
}
</style>