<template>
  <div class="messageItem" v-bind:class="{fromCurrentUser: fromCurrentUser, fromOrg: fromOrg}">
    <!--
    <div style="font-size: .8em; margin-bottom: .3em; margin-left: .5em;">
      <b v-if="fromOrg">{{org.name}}</b>
      <b v-else>{{message.senderId}}</b>
    </div>
    -->
    <div class="messageItemBackground" v-bind:class="{fromCurrentUserColor: fromCurrentUser, fromOrgColor: fromOrg}">
      <div style="font-size: 1em;">
        {{ message.content }}
      </div>
    </div>
    <div style="font-size: .7em; margin-top: .3em; margin-left: .5em;">
      {{ format(new Date(message.timestamp), 'D. MMM, HH:mm') }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {Chat, Message, Organization} from "@/interfaces/types";
import { format } from "date-fns";
import {authState} from "@/composables/authStore";

export default defineComponent({
  name: "MessageItem",
  props: {
    message: {
      type: Object as () => Message,
      required: true,
    },
    chat: {
      type: Object as () => Chat,
      required: true,
    },
    org: {
      type: Object as () => Organization,
      required: true,
    },
  },
  setup(props) {
    const fromCurrentUser = (authState.currentUserId === props.message.senderId)
    const fromOrg = !(props.chat.authorId === props.message.senderId)
    return {
      fromCurrentUser,
      fromOrg,
      format,
    }
  },
})

</script>

<style scoped>

.messageItem {
  text-align: left;
  margin-bottom: 1.5em;
  float: left;
}
.messageItemBackground {
  background: #F6F7F8;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  border-radius: .5em;
  width: fit-content;
}
.fromOrg {
  align-self: flex-end;
}
.fromCurrentUser {
  float: right;
}
.fromOrgColor {
  background: #1EB8CA;
  color: white;
}
</style>