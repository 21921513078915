
import { defineComponent } from 'vue'
import Textarea from "primevue/textarea/Textarea";
import InlineMessage from "primevue/inlinemessage/InlineMessage";

export default defineComponent({
  name: "FormTextArea",
  components: {
    Textarea,
    InlineMessage,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    outerStyle: {
      type: String,
      default: '',
    },
    validationError: {
      type: String,
      default: '',
    },
  },
  computed: {
    localValue: {
      get(): string|number { return this.modelValue },
      set(value: string|number) { this.$emit('update:modelValue', value) },
    },
    validClass() {
      if (this.validationError) return 'p-invalid'
      else return ''
    },
  },
  watch: {
    localValue: {
      handler(value) {
        this.$emit('update:modelValue', value)
      },
      deep: true,
    },
  },
})
