import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {isAuthenticated} from "@/composables/authStore";

import EventListPage from "@/pages/EventListPage.vue";
import EventDetailPage from "@/pages/EventDetailPage.vue";
import LoginPage from "@/admin-app/Pages/LoginPage.vue";
import ChatPage from "@/admin-app/Pages/ChatPage.vue";
import EventFeedbackPage from "@/pages/EventFeedbackPage.vue";
import OrgEventsPage from "@/admin-app/Pages/OrgEventsPage.vue";
import OrgProfilePage from "@/admin-app/Pages/OrgProfilePage.vue";
import AdminLoadingPage from "@/admin-app/Pages/AdminLoadingPage.vue";

let afterLoginURL: string|null = null

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/events/:orgId?',
    name: 'EventListPage',
    component: EventListPage,
    props: true,
  },
  {
    path: '/event/:eventId/feedback/:reviewId',
    name: 'EventFeedbackPage',
    component: EventFeedbackPage,
    props: true,
  },
  {
    path: '/event/:id',
    name: 'EventDetailView',
    component: EventDetailPage,
    props: true,
  },
  {
    path: '/org/:orgId/events',
    name: 'OrgEventsPage',
    component: OrgEventsPage,
    props: true,
  },
  {
    path: '/org/:orgId/messages/:chatId?',
    name: 'ChatPage',
    component: ChatPage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/org/:orgId/profile',
    name: 'OrgProfilePage',
    component: OrgProfilePage,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/login/loading',
    name: 'AdminLoadingPage',
    component: AdminLoadingPage,
    props: true,
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginPage,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
// Redirect to login if not signed in.
router.beforeEach((to, from, next) => {
  const protectedRoute = to.matched.some(record => {
    return record.meta.requiresAuth
  })
  if (protectedRoute && !isAuthenticated()) {
    afterLoginURL = to.path
    console.log('afterLoginURL:' + afterLoginURL)
    next({ name: 'LoginView', params: { redirectTo: to.path } })
  }
  else {
    next()
  }
})

export default router
