import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router'

import EventListPage from "@/pages/EventListPage.vue";
import EventDetailPage from "@/pages/EventDetailPage.vue";
import EventFeedbackPage from "@/pages/EventFeedbackPage.vue";
import LeaveEventPage from "@/client-app/Pages/LeaveEventPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/events',
  },
  {
    path: '/leave/:id',
    name: 'LeaveEventPage',
    component: LeaveEventPage,
    props: true,
  },
  {
    path: '/events/',
    name: 'EventListView',
    component: EventListPage,
    props: true,
  },
  {
    path: '/e/:eventId/feedback/:reviewId',
    name: 'EventFeedbackPage',
    component: EventFeedbackPage,
    props: true,
  },
  {
    path: '/e/:id',
    name: 'EventDetailView',
    component: EventDetailPage,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router