
import {defineComponent, ref} from 'vue'
import {Listing, Organization, Reservation, Review} from "@/interfaces/types";
import {format} from "date-fns";
import TabView from "primevue/tabview/TabView";
import TabPanel from "primevue/tabpanel/TabPanel";
import ReservationList from "@/components/Events/ReservationList.vue";
import * as api from "@/composables/api-protected";
import EventReviewItem from "@/components/Events/EventReviewItem.vue";
import EventInfo from "@/components/Events/EventInfo.vue";
import Button from "primevue/button/Button";
import EventEditForm from "@/components/Events/EventEditForm.vue";
import {useToast} from "primevue/usetoast";

export default defineComponent({
  name: "OrgEventDetail",
  components: {
    EventEditForm,
    EventInfo,
    EventReviewItem,
    TabView,
    TabPanel,
    ReservationList,
    Button,
  },
  props: {
    event: {
      type: Object as () => Listing,
      required: true,
    },
    org: {
      type: Object as () => Organization,
      required: true,
    },
  },
  setup(props, {emit}) {
    const reservations = ref(Array<Reservation>())
    const reviews = ref(Array<Review>())
    const isEditing = ref(false)
    const isRequestingReviews = ref(false)
    const toast = useToast()

    const getReservations = async () => {
      const reservationData = await api.getReservations(props.event.id)
      if (reservationData) {
        reservations.value = reservationData
      } else {
        console.log(`No reservations found for ${props.event.id}`)
      }
    }

    const getReviews = async () => {
      const reviewData = await api.getReviews(props.event.id)
      if (reviewData) {
        reviews.value = reviewData.filter((value) => { return value.completed})
      } else {
        console.log(`No reviews found for ${props.event.id}`)
      }
    }

    const requestReveiws = async () => {
      isRequestingReviews.value = true
      await api.requestReviews(props.event.id)
      toast.add({severity:'info', summary: 'Tilbakemeldingsforespørsel sendt.', detail: 'SMS med forespørsler om tilbakemelding er sendt til deltakerene.', life: 5000})
      isRequestingReviews.value = false
    }

    const updateData = async () => {
      await getReservations()
      await getReviews()
    }

    const saveEvent = (event: Listing) => {
      emit('save-event', event)
      isEditing.value = false
    }

    return {
      reservations,
      reviews,
      isEditing,
      isRequestingReviews,
      updateData,
      getReservations,
      getReviews,
      saveEvent,
      requestReveiws,
      format,
    }
  },
  mounted() {
    this.updateData()
  },
  watch: {
    event: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateData()
        this.isEditing = false
      }
    },
  },
})

