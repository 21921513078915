import {Listing} from "@/interfaces/types";
import { reactive } from 'vue';
import * as openAPI from "@/composables/api-open";

let eventMap = reactive(new Map<string, Listing>())

export const getEvent = async function (eventId?: string): Promise<Listing> {
    const blankEvent: Listing = {
        address: "",
        description: "", id: "", orgId: "", title: "", locationId: "", hasRegistration: true,
    }
    if (!eventId) return blankEvent

    const event = await openAPI.readEvent(eventId)
    if (event) return event
    return blankEvent
}

export const insertEvent = function (listing: Listing) {
    eventMap.set(listing.id, listing)
}

export const getEventList = async function () {
    eventMap = await openAPI.getAllEvents()
    return Array.from(eventMap.values())
}

export async function getEventsFor(orgId: string) {
    return (await getEventList()).filter((e) => { return e.orgId = orgId })
}
