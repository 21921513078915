
import {defineComponent, ref} from 'vue'
import {Listing, Organization} from "@/interfaces/types";
import {getOrg, isInOrg} from "@/composables/orgStore";
import OrgEventItem from "@/admin-app/Organizations/OrgEventListItem.vue";
import OrgEventDetail from "@/admin-app/Organizations/OrgEventDetail.vue";
import OrgEventCreateItem from "@/admin-app/Organizations/OrgEventCreateItem.vue";
import OrgEventCreate from "@/admin-app/Organizations/OrgEventCreate.vue";
import {getOrgEvents} from "@/composables/api-open";
import {updateListingData} from "@/composables/util";
import Toast from "primevue/toast/Toast";
import {useToast} from "primevue/usetoast";
import * as api from "@/composables/api-protected";
import * as eventStore from "@/composables/eventStore";

export default defineComponent({
  name: "OrgEventsPage",
  components: {
    OrgEventCreate,
    OrgEventCreateItem,
    OrgEventDetail,
    OrgEventItem,
    Toast,
  },
  props: {
    orgId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const orgData = ref<Organization|null>(null)
    const events = ref(Array<Listing>())
    const selectedEvent = ref<Listing|null>(null)
    const toast = useToast();

    const fetchData = async () => {
      const newData = await getOrg(props.orgId)
      if (newData && newData.id !== orgData.value?.id) {
        orgData.value = newData
      }
      const newEvents = await getOrgEvents(props.orgId)
      events.value = Array.from(newEvents.values()).sort((a, b) => {return (b.startTime || 0) - (a.startTime || 0)})
    }

    const reloadEvents = async (selectEventId?: string) => {
      const newEvents = Array.from((await getOrgEvents(props.orgId)).values())
      events.value = newEvents.sort((a, b) => {return (b.startTime || 0) - (a.startTime || 0)})
      if (selectEventId) {
        const newEventIndex = newEvents.find((event) => { return (event.id === selectEventId) })
        if (newEventIndex) {
          selectedEvent.value = newEventIndex
        }
      }
    }

    const createBlankEvent = () => {
      selectedEvent.value = {
        address: "",
        description: "",
        id: "",
        locationId: "",
        orgId: props.orgId,
        title: "",
        hasRegistration: true,
      }
    }

    const onCreateEvent = async (newEvent: Listing) => {
      const newEventId = await api.createEvent(newEvent)
      toast.add({severity:'info', summary: 'Arrangement lagret.', detail: 'Arrangementet har blitt opprettet.', life: 5000})
      await reloadEvents(newEventId)
    }

    const onSaveEvent = async (event: Listing) => {
      await api.updateEvent(event)
      toast.add({severity:'info', summary: 'Endringer lagret.', detail: 'Arrangementet har blitt oppdatert.', life: 5000})
      updateListingData(selectedEvent.value, event)
    }

    return {
      orgData,
      events,
      selectedEvent,
      fetchData,
      createBlankEvent,
      onCreateEvent,
      onSaveEvent,
      isInOrg,
    }
  },
  mounted() {
    this.fetchData()
  },
})

