<template>
<div v-if="event" style="margin-left: auto; margin-right: auto; max-width: 50em;">
  <div style="margin-left: 1.7em; margin-right: 1.7em; margin-bottom: 2em; margin-top: 4em; text-align: left">
    <h2 style="font-size: 1.6em;">{{event.title}}</h2>
    <EventInfo style="margin-bottom: 2.2em;" :event="event" />
    <div class="p-fluid">
      <Button v-if="hasEnded" class="event-button" label="Arrangementet er avsluttet" :disabled="true" />
      <a v-else-if="event.externalRegistrationUrl" :href="event.externalRegistrationUrl">
        <Button class="event-button" label="Gå til påmelding"/>
      </a>
      <Button v-else-if="event.hasRegistration" class="event-button" :label="freeSpots ? 'Meld på' : 'Ingen ledige plasser'" :disabled="!freeSpots" @click="registerState.open=true"/>
      <Button class="event-button" label="Send melding" @click="sendMessageState.open=true" />
    </div>
  </div>
  <Sidebar v-model:visible="registerState.open"
           position="bottom"
           @hide="registerState.status = ''"
           style="height: 90%; overflow: scroll">
    <SubmitFormView :submit-state="registerState" @close="registerState.open = false">
      <RegistrationForm @register="registerForEvent"/>
    </SubmitFormView>
  </Sidebar>
  <Sidebar v-model:visible="sendMessageState.open"
           position="bottom"
           @hide="sendMessageState.status = ''"
           style="height: 90%; overflow: scroll">
    <SubmitFormView :submit-state="sendMessageState" @close="sendMessageState.open = false">
      <SendMessageForm  @sendMessage="sendMessage" />
    </SubmitFormView>
  </Sidebar>
</div>
  <div v-else style="margin-top: 50%">
    <ProgressBar mode="indeterminate" style="margin: 2em; height: 1em"/>
  </div>
</template>
<script lang="ts">
import {computed, defineComponent, reactive, ref} from 'vue';
import ProgressBar from "primevue/progressbar/ProgressBar";
import EventInfo from "@/components/Events/EventInfo.vue";
import {Listing, Reservation} from "@/interfaces/types";
import {getEvent} from "@/composables/eventStore";
import Button from "primevue/button/Button";
import * as api from "@/composables/api-open";
import RegistrationForm from "@/components/Events/RegistrationForm.vue";
import SendMessageForm from "@/components/Events/SendMessageForm.vue";
import Sidebar from "primevue/sidebar/Sidebar";
import SubmitFormView from "@/components/FormComponents/SubmitFormView.vue";

export default defineComponent({
  name: 'EventDetailPage',
  components: {
    SubmitFormView,
    EventInfo,
    ProgressBar,
    Button,
    Sidebar,
    RegistrationForm,
    SendMessageForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const event = ref<Listing|null>(null)
    const registerState = reactive({
      open: false,
      state: '',
      status: '',
    })
    const sendMessageState = reactive({
      open: false,
      state: '',
      status: '',
    })

    const fetchData = async () => {
      event.value = await getEvent(props.id)
    }

    const registerForEvent = async (reservation: Reservation) => {
      registerState.state = 'submitting'
      registerState.status = 'Registrerer deg...'

      const result = await api.registerForEvent(props.id, reservation)
      if (result.success) {
        registerState.state = 'submitted'
        registerState.status = result.registered ? 'Du er nå meldt på.' : 'Du er allerede påmeldt.'
      } else {
        registerState.state = 'error'
        registerState.status = result.error ? result.error : 'Noe gikk galt!'
      }
    }

    const sendMessage = async (args: {message: string; phoneNumber: string; senderName: string}) => {
      sendMessageState.state = 'submitting'
      sendMessageState.status = 'Sender inn melding...'
      const event_ = event.value
      if (!event_) return
      console.log('Sender Name: ' + args.senderName)
      const success = await api.sendMessageToEvent(event_, args.message, args.phoneNumber, args.senderName)
          .catch(error => {
            sendMessageState.status = error.toString()
            sendMessageState.state = 'error'
          })
      if (success) {
        sendMessageState.status = 'Takk for meldingen. Du vil få svar så snart noen fra organisasjonen har mulighet til å svare.'
        sendMessageState.state = 'submitted'
      } else {
        sendMessageState.status = 'Noe gikk galt!'
        sendMessageState.state = 'error'
      }
    }

    const freeSpots = computed(() => {
      const evnt = event.value
      if (!evnt) return false
      if (!evnt.hasRegistration) return false
      if (!evnt.limit) return true
      if ((evnt.spotsTaken || 0) < evnt.limit) return true
      return false
    })

    const hasEnded = computed(() => {
      return ((event.value?.endTime || 0) < Date.now())
    })

    return {
      event,
      sendMessageState,
      registerState,
      freeSpots,
      hasEnded,
      fetchData,
      registerForEvent,
      sendMessage,
    }
  },
  mounted() {
    this.fetchData()
  },
});

</script>


<style scoped>
  .event-detail img {
    object-fit: fill;
  }
  .event-button {
    margin-bottom: 0.8em;
    height: 3em;
  }

</style>
