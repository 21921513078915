<template>
<div v-if="event">
  <div style="text-align: left; padding-left: 0.9em; padding-bottom: 2em;">
    <b style="font-size: x-large">{{event.title}}</b>
    <br>
    <b style="font-size: small">{{format(new Date(event.startTime), 'D. MMM HH:mm')}} - {{format(new Date(event.endTime), 'D. MMM HH:mm')}}</b>
  </div>
  <TabView>
    <TabPanel header="Info">
      <div class="info-header">
        <h3>Detaljer</h3>
        <Button v-if="!isEditing" style="background-color: #E9E9E9; border: none; color: black;" class="p-button-secondary" label="Endre" @click="isEditing = true"/>
      </div>
      <EventEditForm v-if="isEditing" :org="org" :listing="event" @saveEvent="saveEvent" />
      <div v-else>
        <EventInfo :event="event" />
        <div v-if="event.externalRegistrationUrl" class="p-mt-6" style="text-align: left">
          <h3>Ekstern registrerings-lenke</h3>
          <a :href="event.externalRegistrationUrl">{{event.externalRegistrationUrl}}</a>
        </div>
      </div>
    </TabPanel>
    <TabPanel header="Deltakere">
      <ReservationList :event="event" :reservations="reservations"/>
    </TabPanel>
    <TabPanel header="Tilbakemeldinger">
      <div v-if="reviews.length > 0">
        <EventReviewItem  v-for="review in reviews" :key="review.phoneNumber" :review="review" />
      </div>
      <div v-else-if="event.endTime > Date.now()" style="text-align: left">
        <h4>Arrangementet er ikke ferdig enda.</h4>
        <p>Tilbakemeldinger kan gis på arrangementer etter de er gjennomført.</p>
      </div>
      <div v-else style="text-align: left">
        <h4>Det er ingen tilbakemeldinger på dette arrangementet.</h4>
        <p>Du kan be om tilbakemelding fra alle påmeldte deltakere.</p>
        <Button :disabled="isRequestingReviews" label="Be om tilbakemeldinger" @click="requestReveiws" class="p-mt-2"/>
      </div>
    </TabPanel>
  </TabView>
</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {Listing, Organization, Reservation, Review} from "@/interfaces/types";
import {format} from "date-fns";
import TabView from "primevue/tabview/TabView";
import TabPanel from "primevue/tabpanel/TabPanel";
import ReservationList from "@/components/Events/ReservationList.vue";
import * as api from "@/composables/api-protected";
import EventReviewItem from "@/components/Events/EventReviewItem.vue";
import EventInfo from "@/components/Events/EventInfo.vue";
import Button from "primevue/button/Button";
import EventEditForm from "@/components/Events/EventEditForm.vue";
import {useToast} from "primevue/usetoast";

export default defineComponent({
  name: "OrgEventDetail",
  components: {
    EventEditForm,
    EventInfo,
    EventReviewItem,
    TabView,
    TabPanel,
    ReservationList,
    Button,
  },
  props: {
    event: {
      type: Object as () => Listing,
      required: true,
    },
    org: {
      type: Object as () => Organization,
      required: true,
    },
  },
  setup(props, {emit}) {
    const reservations = ref(Array<Reservation>())
    const reviews = ref(Array<Review>())
    const isEditing = ref(false)
    const isRequestingReviews = ref(false)
    const toast = useToast()

    const getReservations = async () => {
      const reservationData = await api.getReservations(props.event.id)
      if (reservationData) {
        reservations.value = reservationData
      } else {
        console.log(`No reservations found for ${props.event.id}`)
      }
    }

    const getReviews = async () => {
      const reviewData = await api.getReviews(props.event.id)
      if (reviewData) {
        reviews.value = reviewData.filter((value) => { return value.completed})
      } else {
        console.log(`No reviews found for ${props.event.id}`)
      }
    }

    const requestReveiws = async () => {
      isRequestingReviews.value = true
      await api.requestReviews(props.event.id)
      toast.add({severity:'info', summary: 'Tilbakemeldingsforespørsel sendt.', detail: 'SMS med forespørsler om tilbakemelding er sendt til deltakerene.', life: 5000})
      isRequestingReviews.value = false
    }

    const updateData = async () => {
      await getReservations()
      await getReviews()
    }

    const saveEvent = (event: Listing) => {
      emit('save-event', event)
      isEditing.value = false
    }

    return {
      reservations,
      reviews,
      isEditing,
      isRequestingReviews,
      updateData,
      getReservations,
      getReviews,
      saveEvent,
      requestReveiws,
      format,
    }
  },
  mounted() {
    this.updateData()
  },
  watch: {
    event: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateData()
        this.isEditing = false
      }
    },
  },
})

</script>

<style scoped>

.info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  max-height: 2.5em;
  padding-top: 3em;
}

.p-highlight > a {
  border-color: #00B8CC !important;
}

</style>
