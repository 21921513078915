
<template>
  <div style="margin-left: auto; margin-right: auto; max-width: 50em;">
    <div style="margin-top: 1em; margin-left: 1.7em; margin-right: 1.7em; display: flex; flex-direction: row; align-items: center">
      <img style="height: 2em; margin-right: 0.7em;" src="@/assets/dili_logo.png" alt="">
      <h3 style="color: #6B6D73; font-size: 1em;">Sammentreff</h3>
    </div>
    <div style="margin-left: 1.7em; margin-right: 1.7em; margin-bottom: 1.8em; margin-top: 1.5em; text-align: left">
      <h3 style="font-size: 1.4em">Oppdag tilbud og aktiviteter fra frivilligheten.</h3>
      <p><b>Sammentreff</b> er utviklet i samarbeid med Rådet for psykisk helse og Mental Helse Ungdom, og støttet av Stiftelsen Dam.<br><b @click="showPrivacyModal = true" style="cursor: pointer;"><u>Les mer</u></b></p>
    </div>
    <DataView :value="events" layout="grid" :paginator="true" :rows="9" :sortOrder="sortOrder" :sortField="sortField">
      <template #header>
        <div style="width: 100%; display: flex; flex-direction: column;">
            <MultiSelect
                v-model="selectedLocations"
                :multiple="true"
                :options="allLocations"
                optionLabel="label"
                display="chip"
                placeholder="Velg steder"
                style="width: 100%; text-align: left;"
                @complete="searchForLocations"
            />
            <div style="display: flex; flex-direction: row; text-align: left; padding-top: 1em;">
              <SelectButton v-model="types" :options="['Online', 'Fysisk']" :multiple="true" style="padding-right: 1em;"/>
              <ToggleButton v-model="expired" onLabel="Utgått" offLabel="Utgått"/>
            </div>
          </div>
      </template>
      <template #grid="slotProps">
        <div class="p-col-12 p-md-6 p-mt-2" style="padding-left: .5em; padding-right: .5em">
          <EventItem :event="slotProps.data" />
        </div>
      </template>
      <template #empty>
        <EventLoadingView>
          <h2>Ingen arrangementer</h2>
          <p>Ingen arrangementer funnet.</p>
        </EventLoadingView>
      </template>
    </DataView>
    <Sidebar v-model:visible="showPrivacyModal"
             position="bottom"
             style="height: 90%; overflow: scroll">
      <PrivacyPolicyView />
    </Sidebar>
  </div>
</template>

<script lang="ts">
import * as eventStore from "@/composables/eventStore";
import {computed, defineComponent, ref, watch} from "vue";
import {Category, Listing} from "@/interfaces/types";
import DataView from "primevue/dataview/DataView";
import * as api from "@/composables/api-open";
import MultiSelect from "primevue/multiselect/MultiSelect";
import EventLoadingView from "@/components/Events/EventLoadingView.vue";
import EventItem from "@/components/Events/EventItem.vue";
import PrivacyPolicyView from "@/components/PrivacyPolicyView.vue";
import Sidebar from "primevue/sidebar/Sidebar";
import EventFilterBar from "@/components/Events/EventFilterBar.vue";
import SelectButton from "primevue/selectbutton/SelectButton";
import ToggleButton from "primevue/togglebutton/ToggleButton";


export default defineComponent({
  name: "EventList",
  components: {
    PrivacyPolicyView,
    EventLoadingView,
    DataView,
    EventItem,
    MultiSelect,
    SelectButton,
    ToggleButton,
    Sidebar,
  },
  props: {
    orgId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sortKey: null as null|string,
      sortField: 'startTime',
      sortOptions: [
        {label: 'Last to first', value: '!startTime'},
        {label: 'First to last', value: 'startTime'},
      ],
    }
  },
  setup(props) {
    const showPrivacyModal = ref(false)
    const allEvents = ref(Array<Listing>())
    const filteredEvents = ref(Array<Listing>())
    const allLocations = ref(Array<Category>())
    const selectedLocations = ref(Array<Category>())

    const types = ref(new Array<string>())
    const expired = ref(false)

    const filterEvents = () => {
      const showOnline = types.value.includes('Online')
      const showPhysical = types.value.includes('Fysisk')

      const events = allEvents.value.filter ((event) => {
        const hasExpired = ((event.endTime || Number.MAX_VALUE) < Date.now())
        const isCorrectType = (showOnline && event.isOnline) || (showPhysical && !event.isOnline)
            || (!showOnline && !showPhysical)
        return isCorrectType && (expired.value ? hasExpired : !hasExpired)
      })

      const locations = selectedLocations.value
      if (locations.length > 0) {
        const locationIds = locations.map( (a: Category) => { return a.id })
        filteredEvents.value = events.filter((event) => {
          return (
              locationIds.includes(event.locationId || '')
          )
        })
      } else {
        filteredEvents.value = events
      }
    }

    const loadData = async () => {
      allLocations.value = (await api.getAllLocations()).sort((a, b) => {
        if (a.id === 'landsdekkende') return -1
        return a.label.localeCompare(b.label)});
      const fetchedEvents = (await eventStore.getEventList())
      if (props.orgId) {
        // Filter out all events from other orgs.
        allEvents.value = fetchedEvents.filter ((event) => { return event.orgId === props.orgId })
      } else {
        allEvents.value = fetchedEvents
      }
      filterEvents()
    }

    const searchForLocations = async (event: any) => {
      const query = event.query as string
      allLocations.value = (await api.getAllLocations()).filter((val) => { return val.label.toLowerCase().startsWith(query.toLowerCase()) })
    }

    watch(selectedLocations, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterEvents()
      }
    })

    watch(types, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterEvents()
        console.log('Update filter')
      }
    })

    watch(expired, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        filterEvents()
        console.log('Update filter')
      }
    })

    const sortOrder = computed(() => {
      return expired.value ? -1 : 1
    })

    return {
      events: filteredEvents,
      allLocations,
      selectedLocations,
      showPrivacyModal,
      types,
      expired,
      sortOrder,
      loadData,
      searchForLocations,
    }
  },
  async mounted() {
    await this.loadData()
  },
  watch: {
    orgId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
  },
})
</script>

<style lang="css" scoped>
  .p-dataview.p-component.p-dataview-grid ::v-deep .p-dataview-header {
    background-color: white !important;
    border: none !important;
    padding: 1em 1.2em;
  }

  .p-dataview.p-component.p-dataview-grid ::v-deep .p-dataview-content > div > div > a > div {
    background-color: #F6F7F8;
  }
</style>
