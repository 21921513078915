<template>
  <div v-bind:class="computedClass" >
    <label :for="label+'-input'"><b>{{label}}</b></label>
    <small v-if="description" :id="label+'-input'+'-help'"><br>{{description}}</small>
    <AutoComplete :disabled="disabled" v-model="localValue" :suggestions="suggestions" style="margin-top: .5em" @complete="searchAddress($event)"/>
    <InlineMessage v-if="validationError">{{validationError}}</InlineMessage>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import AutoComplete from "primevue/autocomplete/AutoComplete";
import {getAddressSuggestions} from "@/composables/api-open";
import InlineMessage from "primevue/inlinemessage/InlineMessage";

export default defineComponent({
  name: "AddressAutocomplete",
  components: {
    AutoComplete,
    InlineMessage,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      suggestions: new Array<string>(),
    }
  },
  computed: {
    localValue: {
      get(): string { return this.modelValue },
      set(value: string) { this.$emit('update:modelValue', value) },
    },
    computedClass() {
      let classes = ''
      if (this.disabled) classes += 'p-disable; '
      if (this.validationError) classes += 'p-invalid; '
      return classes
    },
  },
  watch: {
    localValue: {
      handler(value) {
        this.$emit('update:modelValue', value)
      },
      deep: true,
    },
  },
  methods: {
    async searchAddress(event: any) {
      const query = event.query
      this.suggestions = await getAddressSuggestions(query, this.locationId)
    },
  },
})

</script>

<style scoped>

</style>