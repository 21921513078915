<template>
  <div style="text-align: left">
    <br><br>
    <div style="margin-left: auto; margin-right: auto; max-width: 50em;">
      <p>
        <b>Sammentreff</b> er utviklet i samarbeid med Rådet for psykisk helse og Mental Helse Ungdom, og støttet av Stiftelsen Dam.
      </p>
      <p>
        Tjenesten er helt <b>anonym</b>. Ingen opplysninger vil bli delt med andre. Les vår <a href="/privacy-policy.html"><u>personvernerklæring</u></a> for mer informasjon.
      </p>
      <p>
        Har du spørsmål eller innspill til oss i Sammentreff? Send oss en mail på <b>hei@sammentreff.no</b>
      </p>
    </div>
  </div>
</template>

<script>
export default {
name: "PrivacyPolicyView",
}
</script>

<style scoped>

</style>
