import axios from "axios";
import {Category, Listing, ListingData, Organization, Reservation} from "@/interfaces/types";

console.log(`Using endpoint ${process.env.VUE_APP_ENDPOINT_URL} for the open API calls.`)
const RESTEndpoint = process.env.VUE_APP_ENDPOINT_URL

const getHeaders = () => {
    const headers: {[index: string]: string} = {
        'Content-Type': 'application/json',

    }
    return headers
}

async function executeAPIPost(path: string, data: any): Promise<any> {
    const headers = getHeaders()

    return axios.post(RESTEndpoint + path, { "data" : data }, { headers: headers })
        .then(function (response) {
            const result = response.data
            const error = result.error
            if (error) {
                console.error(`Error executing ${path}: ${error}`)
                return {
                    status: response.status,
                    error: error,
                }
            }
            return result
        }).catch(function (error) {
            console.error(error)
            return {
                status: error.response.status,
                error: error.response.data,
            }
        });
}

async function executeAPIGet(path: string): Promise<any> {
    const headers = getHeaders()

    return axios.get(RESTEndpoint + path, { headers: headers })
        .then(function (response) {
            const result = response.data
            const error = result.error
            if (error) {
                console.error(`Error executing ${path}: ${error}`)
                return null
            }
            return result
        }).catch(function (error) {
            console.error('Error executing REST call:')
            console.error(error)
            return null
        });
}

export const getAllEvents = async function (): Promise<Map<string, Listing>> {
    return executeAPIGet('events').then((result) => {
        const events = new Map<string, Listing>()
        if (!result) return events
        const eventData = result.events as { [index: string]: ListingData }

        Object.keys(eventData).forEach( eventId => {
            const event: Listing = { id: eventId, hasRegistration: eventData[eventId].hasRegistration || false, ...eventData[eventId]}
            events.set(eventId, event)
        })
        console.log('Loaded ' + events.size  + ' events.')
        return events
    })
}

export const getOrgEvents = async function (orgId: string): Promise<Map<string, Listing>> {
    return executeAPIGet('events/'+orgId).then((result) => {
        const events = new Map<string, Listing>()
        if (!result) return events
        const eventData = result.events as { [index: string]: ListingData }

        Object.keys(eventData).forEach( eventId => {
            const event: Listing = { id: eventId, hasRegistration: eventData[eventId].hasRegistration || false, ...eventData[eventId]}
            events.set(eventId, event)
        })
        return events
    })
}

export const readEvent = async function (eventId: string): Promise<Listing | null> {
    return executeAPIGet(`event/${eventId}`).then((result) => {
        if (!result) return null
        const eventData = result.event as ListingData
        const event: Listing = {id: eventId, hasRegistration: eventData.hasRegistration || false, ...eventData}
        return event

    })
}


export async function getOrganization(orgId: string) {
    if (orgId === '') return null
    return executeAPIGet(`org/${orgId}`).then((result) => {
        if (!result) return null
        const error = result.error
        if (error) {
            console.error(`Error getting org with id ${orgId}: ${error}`)
        }
        return result.org as Organization
    })
}

export async function postReview(eventId: string, reviewId: string, rating: number, text: string) {
    const data = {
        "rating": rating,
        "text": text,
    }
    return executeAPIPost(`event/${eventId}/feedback/${reviewId}`, data)
}


export async function registerForEvent(eventId: string, reservation: Reservation): Promise<{success: boolean; registered: boolean; error?: string}> {
    const data = {
        "eventId": eventId,
        "phoneNumber": reservation.phoneNumber,
        "age": reservation.age || '',
        "gender": reservation.gender || '',
        "name": reservation.name || '',
    }

    return executeAPIPost('event/join', data).then((result) => {
        if (!result) return {success: false, registered: false}
        const error = result.error
        console.log(result.status)
        if (error) {
            console.error(`Error joining event with id ${eventId}: ${error}`)
            if (result.status === 409) {
                console.warn('Attempted to join full event.')
                return {success: false, registered: false, error: 'Arrangementet er fullt.'}
            }
            return {success: false, registered: false, error: 'Arrangementet er fullt.'}
        }
        return {success: result.success, registered: result.registered}
    })
}

export async function getEventIdForUnregisterKey(unregisterKey: string): Promise<string|null> {

    return executeAPIGet(`events/leave/eventIdForKey/${unregisterKey}`).then((result) => {
        if (!result) return null
        const error = result.error
        if (error) {
            console.error(`Error unregistering from event with key ${unregisterKey}: ${error}`)
            return null
        }
        return result.eventId
    })
}

export async function unregisterFromEvent(unregisterKey: string): Promise<{success: boolean; eventId?: string}> {

    return executeAPIPost(`event/leave/${unregisterKey}`, {}).then((result) => {
        if (!result) return {success: false}
        const error = result.error
        if (error) {
            console.error(`Error unregistering from event with key ${unregisterKey}: ${error}`)
            return {success: false}
        }
        return {success: result.success, eventId: result.eventId}
    })
}

export async function sendMessageToEvent(event: Listing, message: string, phoneNumber: string, senderName: string): Promise<boolean> {
    const data: { [key: string]: any } = {
        "eventId": event.id,
        "orgId": event.orgId,
        "phoneNumber": phoneNumber,
        "messageText": message,
    }
    if (senderName !== '') {
        data["senderName"] = senderName
    }
    return executeAPIPost('event/sendMessage', data).then((result) => {
        if (!result) return false
        const error = result.error
        if (error) {
            console.error(`Error sending message to event with id ${event.id}: ${error}`)
            return false
        }
        return true
    })
}

/** Categories and Locations */
export const getAllLocations = async function(): Promise<Category[]> {
    return executeAPIGet('locations').then((result) => {
        if (!result || !result.categories) return new Array<Category>()
        return result.categories as Array<Category>
    })
}

export const getLocation = async function(locationId: string): Promise<Category|null> {
    return executeAPIGet(`locations/${locationId}`).then((result) => {
        if (!result) return null
        const error = result.error
        if (error) {
            console.error(`Error getting location with id ${locationId}: ${error}`)
        }
        return result.location as Category
    })
}

export const getAddressSuggestions= async function(term: string, locationId: string): Promise<Array<string>> {
    return executeAPIGet(`address/suggestions/${locationId}/${term}`).then((result) => {
        if (!result) return new Array<string>()
        const error = result.error
        if (error) {
            console.error(`Error getting location with id ${locationId}: ${error}`)
        }
        const suggestions = result.suggestions as Array<string>
        return suggestions
    })
}
