
import {defineComponent} from "vue";
import RadioButton from "primevue/radiobutton/RadioButton";
import InlineMessage from "primevue/inlinemessage/InlineMessage";

export default defineComponent({
  name: "RadioGroup",
  components: {
    RadioButton,
    InlineMessage,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    validationError: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
  },

})
