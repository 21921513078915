import {reactive} from "vue";
import {Category} from "@/interfaces/types";
import * as api from "@/composables/api-open";


const locationMap = reactive(new Map<string, Category>())

export async function getLocation(id: string) {
    if (!id) return null
    const cached = locationMap.get(id)
    if (cached) {
        return cached
    }

    const fetched = await api.getLocation(id)
    if (fetched) {
        locationMap.set(id, fetched)
    }
    return fetched
}

export async function getLocations(ids: Array<string>) {
    const locations = Array<Category>()
    if (!ids) return locations
    for (const id of ids) {
        const location = await getLocation(id)
        if (location) locations.push(location)
    }
    console.log(locations)
    return locations
}