<template>
  <div class="event-info">
    <div class="event-info-itemWrapper">
      <div class="event-info-item p-my-3">
        <img alt="flagg" class="icon" src="@/assets/icons/flag.svg"><span v-if="orgData">{{orgData.name}}</span>
      </div>
      <div class="event-info-item p-my-3">
        <img alt="klokke" class="icon" src="@/assets/icons/time.svg"><span>{{format(new Date(event.startTime), 'D. MMM HH:mm')}} - {{format(new Date(event.endTime), 'D. MMM HH:mm')}}</span>
      </div>
      <div v-if="event.isOnline" class="event-info-item p-my-3" style="text-decoration: underline">
        <img alt="lokasjon" class="icon" src="@/assets/icons/location.svg"><span><a :href="eventUrl">{{event.address}}</a></span>
      </div>
      <div v-else class="event-info-item p-my-3">
        <img alt="lokasjon" class="icon" src="@/assets/icons/location.svg"><span>{{event.address}}</span>
      </div>
      <div v-if="event.hasRegistration" class="event-info-item p-my-3">
        <img alt="personer" class="icon" src="@/assets/icons/people.svg"><span>{{event.spotsTaken || 0}}{{event.limit ? '/'+event.limit : ''}} deltakere </span>
      </div>
    </div>
    <p v-html="event.description" v-linkified />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import {Listing, Organization} from "@/interfaces/types";
import {getOrg} from "@/composables/orgStore";
import {format} from "date-fns";

export default defineComponent({
  name: "EventInfo",
  props: {
    event: {
      type: Object as () => Listing,
      required: true,
    },
  },
  setup(props) {
    const orgData = ref<Organization|null>(null)

    const fetchData = async () => {
      const newData = await getOrg(props.event.orgId)
      if (newData && newData.id !== orgData.value?.id) {
        orgData.value = newData
      }
    }

    const eventUrl = computed(() => {
      const url = props.event.address
      if (!url.startsWith('http')) return 'https://' + url
      return url
    })

    return {
      orgData,
      eventUrl,
      fetchData,
      format,
    }
  },
  mounted() {
    this.fetchData()
  },
})

</script>

<style scoped>

.event-info {
  text-align: left;
  white-space: pre-wrap;
}

.event-info-itemWrapper {
  margin-top: 2.2em;
  margin-bottom: 2.2em;
}

.event-info-item {
  display: flex;
  align-items: center;
}

.icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
</style>
