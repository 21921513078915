import * as api from "@/composables/api-open";
import {Organization} from "@/interfaces/types";
import {reactive, ref} from "vue";

const orgMap = reactive(new Map<string, Organization>())
export const currentOrg = ref<Organization|null>(null)

export async function getOrg(id: string) {
    const cached = orgMap.get(id)
    if (cached) {
        return cached
    }

    const fetched = await api.getOrganization(id)
    if (fetched) {
        orgMap.set(id, fetched)
    }
    return fetched
}


export const activeOrganizations = ref(Array<Organization>())

export const activeOrgIds = function () {
    return activeOrganizations.value.map((org) => { return org.id })
}

export function isInOrg(orgId: string) {
    return activeOrgIds().includes(orgId)
}