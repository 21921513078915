import { createApp } from 'vue'
import 'firebaseui/dist/firebaseui.css';
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import PrimeVue from 'primevue/config'
import Calendar from "primevue/calendar/Calendar";
import Card from "primevue/card/Card";
import DataView from "primevue/dataview/DataView";
import Dropdown from "primevue/dropdown/Dropdown";
import Button from "primevue/button/Button";
import AutoComplete from "primevue/autocomplete/AutoComplete";
import Dialog from "primevue/dialog/Dialog";
import Textarea from "primevue/textarea/Textarea";
import InputText from "primevue/inputtext/InputText";
import DataTable from "primevue/datatable/DataTable";
import Column from "primevue/column/Column";
import ColumnGroup from "primevue/columngroup/ColumnGroup";
import ProgressBar from "primevue/progressbar/ProgressBar";
import ScrollPanel from "primevue/scrollpanel/ScrollPanel";
import Rating from "primevue/rating/Rating";
import TabView from "primevue/tabview/TabView";
import TabPanel from "primevue/tabpanel/TabPanel";
import TabMenu from "primevue/tabmenu/TabMenu";
import RadioButton from "primevue/radiobutton/RadioButton";
import Toast from "primevue/toast/Toast";
import ToastService from "primevue/toastservice"
import InlineMessage from "primevue/inlinemessage/InlineMessage";
import MultiSelect from "primevue/multiselect/MultiSelect";
import AdminApp from './AdminApp.vue'
import ClientApp from "@/ClientApp.vue";
import Sidebar from "primevue/sidebar/Sidebar";
import ProgressSpinner from "primevue/progressspinner/ProgressSpinner";
import SelectButton from "primevue/selectbutton/SelectButton";
import ToggleButton from "primevue/togglebutton/ToggleButton";
import linkify from 'vue-linkify'

//Vue.directive('linkified', linkify)
enum AppType {
    Admin,
    Client
}

const appType = process.env.VUE_APP_BUILD_TARGET === 'client' ? AppType.Client : AppType.Admin
const router = appType === AppType.Client ? require('./client-app/router').default : require('./admin-app/router').default
const app = createApp(appType === AppType.Client ? ClientApp : AdminApp)
    .use(ToastService)
    .use(router)
    .use(PrimeVue)

app.directive('linkified', linkify)

/** Shared components */
app.component('Card', Card)
app.component('Button', Button)
app.component('Dialog', Dialog)
app.component('Textarea', Textarea)
app.component('InputText', InputText)
app.component('RadioButton', RadioButton)
app.component('Toast', Toast)
app.component('InlineMessage', InlineMessage)
app.component('DataView', DataView)
app.component('Sidebar', Sidebar)
app.component('ScrollPanel', ScrollPanel)
app.component('ProgressSpinner', ProgressSpinner)
app.component('SelectButton', SelectButton)
app.component('ToggleButton', ToggleButton)


/** Admin specific components */
if (appType === AppType.Admin) {
app.component('Calendar', Calendar)
app.component('Dropdown', Dropdown)
app.component('AutoComplete', AutoComplete)
app.component('MultiSelect', MultiSelect)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('ProgressBar', ProgressBar)
app.component('Rating', Rating)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('TabMenu', TabMenu)
}
app.mount('#app')
