<template>
<div class="item" v-bind:class="{ selectedItem: selected }">
    <div class="chat-item-header">
      <small>
      <b>{{ chat.authorName || chat.authorId }}</b>
        <br>
      </small>
      <small>{{ format(new Date(chat.timestamp), 'HH:mm') }}</small>
    </div>
  <small>{{chat.title || ' ... '}}</small>
</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {Chat} from "@/interfaces/types";
import {format} from "date-fns";

export default defineComponent({
  name: "ChatListItem",
  props: {
    chat: {
      type: Object as () => Chat,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { format }
  },
})

</script>

<style scoped>
.item {
  background: #FFFFFF;
  text-align: left;
  padding: 1em;
  white-space: pre-wrap;
}
.selectedItem {
  background: #1EB8CA;
  color: white;
}
.chat-item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}
</style>
