<template>
  <div v-if="showProgressBar" >
  <ProgressBar mode="indeterminate" style="margin: 10em; height: .5em;"/>
  </div>
  <div v-else>
  <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import ProgressBar from "primevue/progressbar/ProgressBar";

export default defineComponent({
  name: "EventLoadingView",
  components: {
    ProgressBar,
  },
  props: {
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      showProgressBar: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.showProgressBar = false
    }, this.timeout)
  },
})

</script>

<style scoped>

</style>
