import {Listing, Organization} from "@/interfaces/types";

export function roundDateToNearest(date: Date, minutes: number) {
    const roundingFactor = 1000 * 60 * minutes;
    return new Date(Math.round(date.getTime() / roundingFactor) * roundingFactor)
}



export function updateListingData(listing: Listing|null, from: Listing) {
    if (!listing) return
    listing.id = from.id
    listing.orgId = from.orgId
    listing.title = from.title
    listing.description = from.description
    listing.address = from.address
    listing.startTime = from.startTime
    listing.endTime = from.endTime
    listing.imageUrl = from.imageUrl
    listing.limit = from.limit
    listing.spotsTaken = from.spotsTaken
    listing.isOnline = from.isOnline
    listing.hasRegistration = from.hasRegistration
    listing.externalRegistrationUrl = from.externalRegistrationUrl
}

export function updateOrgData(org: Organization|null, from: Organization) {
    if (!org) return
    org.id = from.id
    org.name = from.name
    org.description = from.description
    org.locationIds = from.locationIds
}
