<template>
  <div v-if="orgId">
    <h1 v-if="org">{{org.name}} sine arrangementer</h1>
  </div>
  <EventList :org-id="orgId" />
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import EventList from "@/components/Events/EventList.vue";
import {getOrganization} from "@/composables/api-open";
import {Organization} from "@/interfaces/types";

export default defineComponent({
  name: 'EventListPage',
  components: {
    EventList,
  },
  props: {
    orgId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const org = ref<Organization|null>(null)
    const fetchData = async () => {
      org.value = await getOrganization(props.orgId)
    }

    return { org, fetchData }
  },
  mounted() {
    this.fetchData()
  },

});
</script>


<style scoped>

</style>
