
import {computed, defineComponent, reactive, ref, watch} from 'vue'
import Button from "primevue/button/Button"
import FormInput from "@/components/FormComponents/FormInput.vue";
import FormTextArea from "@/components/FormComponents/FormTextArea.vue";
import {validatePhoneNumber} from "@/interfaces/validators";

export default defineComponent({
  name: "SendMessageForm",
  emits: ['send-message'],
  components: {
    FormTextArea,
    Button,
    FormInput,
  },
  setup(props, {emit}) {
    const phoneNumber = ref('')
    const message = ref('')
    const senderName = ref('')
    const validationErrors = reactive({
      phone: '',
      message: '',
    })

    const isValid = computed(() => {
      return !(validationErrors.phone || validationErrors.message)
    })

    const validate = (field?: string) => {
      if (!field || field === 'phone') {
        validationErrors.phone = validatePhoneNumber(phoneNumber.value)
      }
      if (!field || field === 'message') {
        // eslint-disable-next-line eqeqeq
        if (message.value === '') {
          validationErrors.message = 'Du må skrive inn en melding.'
        } else {
          validationErrors.message = ''
        }
      }
      const valid = !(validationErrors.phone || validationErrors.message)
      return valid
    }

    const sendMessage = () => {
      if (validate()) {
        emit('send-message', {message: message.value, phoneNumber: phoneNumber.value, senderName: senderName.value})
      } else {
      }
    }

    watch(message, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        validate('message')
      }
    })

    watch(phoneNumber, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        if (validationErrors.phone) {
          // Skips the validation errors while typing the number for the first time.
          validate('phone')
        }
      }
    })

    return {
      phoneNumber,
      message,
      isValid,
      senderName,
      validationErrors,
      sendMessage,
      validate,
    }
  },
})
