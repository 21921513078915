
import {defineComponent} from 'vue'
import ProgressBar from "primevue/progressbar/ProgressBar";

export default defineComponent({
  name: "EventLoadingView",
  components: {
    ProgressBar,
  },
  props: {
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      showProgressBar: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.showProgressBar = false
    }, this.timeout)
  },
})

