
import {Listing} from "@/interfaces/types";
import {defineComponent} from 'vue'
import {format} from "date-fns";


export default defineComponent({
  name: "OrgEventListItem",
  components: {
  },
  props: {
    event: {
      required: true,
      type: Object as () => Listing,
    },
    selected: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { format }
  },
})


