
import {defineComponent, watch} from 'vue'
import ProgressBar from "primevue/progressbar/ProgressBar";
import {currentOrg} from "@/composables/orgStore";
import router from "@/admin-app/router";

export default defineComponent({
  name: "AdminLoadingPage",
  components: {
    ProgressBar,
  },
  setup() {
    if (currentOrg.value) {
      router.push(`/org/${currentOrg.value.id}/events`)
    }

    watch(currentOrg, (newValue) => {
      if (newValue) {
        router.push(`/org/${newValue.id}/events`)
      }
    })
  },
})

