
import {defineComponent} from 'vue'
import ProgressBar from "primevue/progressbar/ProgressBar";
import Button from "primevue/button/Button";

export interface SubmitState {
  state: string;
  status: string;
}

export default defineComponent({
  name: "SubmitFormView",
  emits: ['close'],
  components: {
    ProgressBar,
    Button,
  },
  props: {
    submitState: {
      type: Object as () => SubmitState,
      required: true,
    },
  },
})

