<template>
  <div class="p-mx-2">
    <div style="margin-left: auto; margin-right: auto; max-width: 50em;">
      <div style="text-align: left;">
        <br><br>
        <b>For å melde deg på trenger vi litt info.</b>
      </div>
      <form class="p-fluid" @submit.prevent="onSubmit" style="text-align: left">
        <div class="p-field">
          <div class="p-mt-4 p-mb-4">
            <RadioGroup v-model="gender"
                        :options="genderOptions"
                        name="Kjønn"
                        :validation-error="validationErrors.gender"
            />
          </div>
          <div>
            <RadioGroup v-model="age" :options="ageOptions" name="Alder" :validation-error="validationErrors.age"/>
          </div>
          <div class="p-mb-2 p-mt-4">
            <FormInput v-model="phoneNumber"
                      label="Kontaktinformasjon*"
                      description="*Informasjonen vil ikke bli delt med noen."
                      placeholder="Telefonnummer"
                      :validation-error="validationErrors.phone"
            />
          </div>
          <div class="p-mb-2 p-mt-4">
            <FormInput v-model="name"
                       label="Navn"
                       description="Valgfritt. Vil ikke deles med andre enn arrangøren."
                       placeholder="Navn"
            />
          </div>
        </div>
        <Button data-cy="submit-registration" :disabled="!isValid" type="submit" label="Meld på" style="margin-top: 1em"/>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent, reactive, ref, watch, computed} from 'vue'
import {Reservation} from "@/interfaces/types"
import Button from "primevue/button/Button"
import RadioGroup from "@/components/FormComponents/RadioGroup.vue";
import FormInput from "@/components/FormComponents/FormInput.vue";
import {validatePhoneNumber} from "@/interfaces/validators";

export default defineComponent({
  name: "RegistrationForm",
  components: {
    FormInput,
    RadioGroup,
    Button,
  },
  data() {
    return {
      genderOptions: [
        { value: "mann",  label: "Mann" },
        { value: "kvinne", label: "Kvinne" },
        { value: "annet", label: "Annet" },
      ],
      ageOptions: [
        { value: 13,  label: "13-19" },
        { value: 20, label: "20-35" },
        { value: 36, label: "36-49" },
        { value: 50, label: "50+" },
      ],
    }
  },
  setup(props, {emit}) {
    const phoneNumber = ref('')
    const age = ref(0)
    const gender = ref('')
    const name = ref('')
    const validationErrors = reactive({
      phone: '',
      age: '',
      gender: '',
    })

    const validateForm = (field?: string) => {

      if (!field || field === 'phone') {
        validationErrors.phone = validatePhoneNumber(phoneNumber.value)
      }

      if (!field || field === 'age') {
        if (age.value <= 0) {
          validationErrors.age = "Ugyldig alder"
        } else {
          validationErrors.age = ''
        }
      }
      if (!field || field === 'gender') {
        if (gender.value.length <= 0) {
          validationErrors.gender = "Kjønn mangler"
        } else {
          validationErrors.gender = ''
        }
      }
      return !(validationErrors.phone || validationErrors.age || validationErrors.gender)
    }

    watch(gender, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        validateForm('gender')
      }
    })

    watch(age, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        validateForm('age')
      }
    })

    watch(phoneNumber, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        if (validationErrors.phone) {
          // Skips the validation errors while typing the number for the first time.
          validateForm('phone')
        }
      }
    })

    const onSubmit = () => {
      const isValid = validateForm()
      if (isValid) {
        const reservation: Reservation = {
          attending: true,
          phoneNumber: phoneNumber.value,
          gender: gender.value,
          age: age.value,
        }
        if (name.value !== '') {
          reservation.name = name.value
        }
        emit('register', reservation)
        return
      }
    }

    const isValid = computed(() => {
      return !(validationErrors.phone || validationErrors.age || validationErrors.gender)
    })

    return {
      phoneNumber,
      age,
      gender,
      name,
      validationErrors,
      isValid,
      validateForm,
      onSubmit,
    }
  },
})
</script>
<style scoped>
</style>
