<template>
  <div class="item" v-bind:class="{ selectedItem: selected }">
    <div>
      <span style="display: flex; align-items: center;">
        <b style="font-size: 2.5em; margin-bottom: 0.18em; margin-right: 0.3em;">+</b> <small>Opprett nytt arrangement</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrgEventCreateItem",
  props: {
    selected: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.item {
  background: #FFFFFF;
  text-align: left;
  padding: 1em;
  width: 100%;
}
.selectedItem {
  background: #1EB8CA;
  color: white;
}
</style>