
import {computed, defineComponent, reactive, ref} from 'vue'
import FormInput from "@/components/FormComponents/FormInput.vue";
import {Category, Listing, Organization} from "@/interfaces/types";
import FormTextArea from "@/components/FormComponents/FormTextArea.vue";
import Button from "primevue/button/Button";
import InputNumber from 'primevue/inputnumber/InputNumber';
import AddressAutocomplete from "@/components/FormComponents/AddressAutocomplete.vue";
import RadioButton from "primevue/radiobutton/RadioButton";
import Calendar from "primevue/calendar/Calendar";
import Dropdown from "primevue/dropdown/Dropdown";
import {getLocations} from "@/composables/categoryStore";

export default defineComponent({
  name: "EventEditForm",
  props: {
    org: {
      type: Object as () => Organization,
      required: true,
    },
    listing: {
      required: true,
      type: Object as () => Listing,
    },
    createMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Calendar,
    Dropdown,
    AddressAutocomplete,
    FormTextArea,
    FormInput,
    Button,
    InputNumber,
    RadioButton,
  },

  setup(props, {emit}) {

    const listing = props.listing as Listing
    const isOnline = ref<boolean>(props.listing.isOnline || false)
    const registrationType = ref('none')
    const currentDate = ref<Date>(new Date())
    const startDate = ref<Date|null>(listing.startTime ? new Date(listing.startTime) : null)
    const endDate = ref<Date|null>(listing.endTime ? new Date(listing.endTime) : null)
    const orgLocations = ref(Array<Category>())
    const selectedLocation = ref<Category|null>(null)

    if (props.listing.externalRegistrationUrl) registrationType.value = 'external'
    if (props.listing.hasRegistration) registrationType.value = 'internal'

    const validationErrors = reactive({
      title: '',
      description: '',
      address: '',
      start: '',
      end: '',
      location: '',
      externalRegistrationUrl: '',
    })

    const updatedEvent = ref<Listing>( {
      ...listing,
    })

    function getLocationId() {
      if (props.org.locationIds.length === 1) return props.org.locationIds[0] || null
      const locationId = selectedLocation.value?.id
      return locationId || null
    }

    const validateData = (field?: string) => {
      if (!field || field === 'title') validationErrors.title = updatedEvent.value.title ? '' : 'Tittel kan ikke være tom.'
      if (!field || field === 'description') validationErrors.description = updatedEvent.value.description ? '' : 'Beskrivelsen kan ikke være tom.'
      if (!field || field === 'start') validationErrors.start = startDate.value ? '' : 'Start-tid mangler.'
      if (!field || field === 'end') {
        if (!endDate.value) validationErrors.end = 'Slutt-tid mangler.'
        else if (startDate.value && (endDate.value < startDate.value || 0)) validationErrors.end = 'Slutt-tid må være etter start-tid.'
        else validationErrors.end = ''
      }
      if (!field || field === 'address') validationErrors.address = isOnline.value || updatedEvent.value.address ? '' : 'Adresse mangler'
      if (!field || field === 'location') validationErrors.location = getLocationId() || updatedEvent.value.locationId ? '' : 'Sted mangler'
      return !(validationErrors.title || validationErrors.description || validationErrors.start || validationErrors.end || validationErrors.location);
    }


    const onSave = () => {
      if (!validateData()) return
      updatedEvent.value.startTime = startDate.value?.getTime()
      updatedEvent.value.endTime = endDate.value?.getTime()
      updatedEvent.value.isOnline = isOnline.value
      const locationId = getLocationId()
      if (locationId) updatedEvent.value.locationId = locationId
      updatedEvent.value.hasRegistration = registrationType.value === 'internal';
      if (registrationType.value !== 'external') {
        updatedEvent.value.externalRegistrationUrl = undefined
      }
      emit('save-event', updatedEvent.value)
    }

    const onCreate = () => {
      if (!validateData()) return
      updatedEvent.value.startTime = startDate.value?.getTime()
      updatedEvent.value.endTime = endDate.value?.getTime()
      updatedEvent.value.isOnline = isOnline.value
      const locationId = getLocationId()
      if (locationId) updatedEvent.value.locationId = locationId
      updatedEvent.value.hasRegistration = registrationType.value === 'internal';
      if (registrationType.value !== 'external') {
        updatedEvent.value.externalRegistrationUrl = undefined
      }
      emit('create-event', updatedEvent.value)
    }

    const loadLocations = async () => {
      orgLocations.value = await getLocations(props.org.locationIds)
      if (listing.locationId.length > 0) {
        selectedLocation.value = orgLocations.value.find(l => l.id === listing.locationId) || null
      }
    }

    return {
      event: updatedEvent,
      isOnline,
      registrationType,
      currentDate,
      startDate,
      endDate,
      validationErrors,
      orgLocations,
      selectedLocation,
      onSave,
      onCreate,
      validateData,
      loadLocations,
    }
  },
  mounted() {
    this.loadLocations()
  },
})

