
import {computed, defineComponent, ref} from 'vue'
import {Chat, Listing, Message, Organization} from "@/interfaces/types";
import MessageItem from "@/admin-app/Messaging/MessageItem.vue";
import {getEvent} from "@/composables/eventStore";
import {getOrg} from "@/composables/orgStore";

export default defineComponent({
  name: "MessageListView",
  components: {
    MessageItem,
  },
  props: {
    chat: {
      type: Object as () => Chat,
      required: true,
    },
    orgId: {
      type: String,
      required: true,
    },
    messages: {
      type: Object as () => [Message],
      required: true,
    },
  },
  setup(props) {
    const event = ref<Listing|null>(null)
    const org = ref<Organization|null>(null)

    const loadData = async () => {
      event.value = await getEvent(props.chat.eventId)
      org.value = await getOrg(props.orgId)
    }

    const authorId = computed(() => {
      if (props.messages.length > 0) {
        return props.messages[0].senderId
      }
      return null
    })

    return {
      event,
      org,
      authorId,
      loadData,
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    chat: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
  },
})

