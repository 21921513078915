
import {defineComponent} from 'vue'
import {Category, Organization} from "@/interfaces/types";

export default defineComponent({
  name: "OrgInfo",
  props: {
    org: {
      type: Object as () => Organization,
      required: true,
    },
    locations: {
      type: Object as () => Array<Category>,
      required: true,
    },
  },
})

