<template>
  <div style="display: flex;">
    <InputText class="p-col" v-model="messageText" placeholder="Skriv melding.." @keyup.enter="onSend"/>
    <Button class="p-col-fixed" style="width: 5em; margin-left: 0.5em" @click="onSend" label="Send" />
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import InputText from "primevue/inputtext/InputText";
import Button from "primevue/button/Button";

export default defineComponent({
  name: "MessageInput",
  emits: ['send-message'],
  components: {
    InputText,
    Button,
  },
  setup(props, {emit}) {
    const messageText = ref('')

    const onSend = () => {
      if (messageText.value) {
        emit('send-message', messageText.value)
      }
      messageText.value = ''
    }

    return {
      messageText,
      onSend,
    }
  },
})
</script>

<style scoped>
.messageInput {
  margin: 20px;
}
.messageInput input {
  height: 30px;
  float: left;
}

.messageInput button {
  height: 30px;
  float: right;
}
</style>