
import {defineComponent, ref} from 'vue'
import {Category, Organization} from "@/interfaces/types";
import {getOrg} from "@/composables/orgStore";
import OrgInfo from "@/admin-app/Organizations/OrgInfo.vue";
import Button from "primevue/button/Button";
import OrgEditProfile from "@/admin-app/Organizations/OrgEditProfile.vue";
import * as api from "@/composables/api-protected";
import {updateOrgData} from "@/composables/util";
import {getLocation, getLocations} from "@/composables/categoryStore";

export default defineComponent({
  name: "OrgProfilePage",
  components: {
    Button,
    OrgInfo,
    OrgEditProfile,
  },
  props: {
    orgId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const org = ref<Organization|null>(null)
    const locations = ref(Array<Category>())

    const isEditing = ref(false)

    const loadData = async () => {
      const orgData = await getOrg(props.orgId)
      org.value = orgData
      if (orgData) {
        locations.value = (await getLocations(orgData.locationIds))
      }
    }

    const onOrgSave = async (updatedOrg: Organization) => {
      isEditing.value = false
      const result = await api.updateOrganization(props.orgId, updatedOrg)
      if (result.success) {
        if (updatedOrg.locationIds !== locations.value.map( l => l.id )) {
          locations.value = await getLocations(updatedOrg.locationIds)
        }
        updateOrgData(org.value, updatedOrg)
      }
    }

    return {
      org,
      locations,
      isEditing,
      loadData,
      onOrgSave,
    }
  },
  mounted() {
    this.loadData()
  },
  watch: {
    orgId: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
  },

})

