
import {defineComponent} from 'vue'
import {Review} from "@/interfaces/types";

export default defineComponent({
  name: "EventReviewItem",
  props: {
    review: {
      type: Object as () => Review,
      required: true,
    },
  },
  setup() {
    const convertToStars = (rating: number) => {
      let stars = ''
      for (let i = 0; i < rating; i++) {
        stars += '⭐️'
      }
      return stars
    }
    return { convertToStars }
  },
})

