<template>
  <form class="p-fluid p-mt-6" style="text-align: left" >
    <div class="p-mt-5" v-if="org.locationIds.length > 1">
      <label><b>Sted</b></label>
      <small><br>På hvilket sted foregår arrangementet?</small>
      <Dropdown v-model="selectedLocation" :options="orgLocations" optionLabel="label" style="min-width: 20em; text-align: left" />
      <InlineMessage v-if="validationErrors.location">{{validationErrors.location}}</InlineMessage>
    </div>
    <div class="p-mt-5 p-field">
      <label><b>Fysisk eller digitalt</b></label>
      <small><br>Skal arrangementet skje digitalt eller fysisk?</small>
      <br>
      <div class="p-field-radiobutton p-mt-2">
        <RadioButton id="radio-online1"
                     name="online"
                     :value="false"
                     v-model="isOnline" />
        <label for="radio-online1">Fysisk sted</label>
        <RadioButton id="radio-online2"
                     name="online"
                     :value="true"
                     @change="validateData"
                     v-model="isOnline"
                     class="p-ml-3" />
        <label for="radio-online2">Digitalt</label>
      </div>
    </div>

    <div v-if="isOnline">
      <FormInput class="p-field p-mt-5" v-model="event.address"
                 label="Nettaddresse"
                 description="Hvor skal arrangementet skje?"
                 placeholder="eksempel.no"
                 type="url"
                 :validation-error="validationErrors.address"
      />
    </div>
    <AddressAutocomplete v-else class="p-field p-mt-5" v-model="event.address"
                         label="Adresse"
                         description="Hvor skal arrangementet skje?"
                         type="text"
                         :location-id="selectedLocation?.id"
                         :validation-error="validationErrors.address"
                         style="margin-bottom: 1em"
    />
    <div class="p-mt-5 p-field">
      <label><b>Kreves påmelding?</b></label>
      <small><br>Kreves det at deltakere melder seg på?</small>
      <br>
      <div class="p-field-radiobutton p-mt-2">
        <RadioButton id="radio-register1"
                     name="register"
                     :value="'none'"
                     v-model="registrationType" />
        <label for="radio-register1">Ingen påmelding</label>
        <RadioButton id="radio-register2"
                     name="register"
                     :value="'internal'"
                     @change="validateData"
                     v-model="registrationType"
                     class="p-ml-3" />
        <label for="radio-register2">Påmelding på Sammentreff</label>
        <RadioButton id="radio-register3"
                     name="register"
                     :value="'external'"
                     @change="validateData"
                     v-model="registrationType"
                     class="p-ml-3" />
        <label for="radio-register3">Påmelding eksternt</label>
      </div>
    </div>
    <div v-if="registrationType === 'external'">
      <FormInput class="p-field p-mt-5" v-model="event.externalRegistrationUrl"
                 label="Lenke til påmelding"
                 description="Legg ved lenke til nettsiden hvor man melder seg på arrangementet"
                 placeholder="eksempel.no"
                 type="url"
                 :validation-error="validationErrors.externalRegistrationUrl"
      />
    </div>
    <div v-if="registrationType === 'internal'" class="p-field p-mt-5">
      <label for="Antall-input"><b>Antall (valgfritt)</b></label>
      <small><br>Hvor mange kan delta på arrangementet?</small>
      <br>
      <InputNumber id="Antall-input" v-model="event.limit" style="margin-top: .5em" />
    </div>
    <FormInput class="p-field p-mt-6" v-model="event.title"
               label="Tittel"
               description="En kort og tydelig beskrivende tittel"
               type="text"
               :validation-error="validationErrors.title"
               style="margin-bottom: 1em"
    />
    <FormTextArea class="p-field" v-model="event.description"
                  label="Beskrivelse"
                  description="Beskriv arrangementet så godt du klarer"
                  type="text"
                  :validation-error="validationErrors.description"
                  outer-style="margin-bottom: 1em"
    />

    <div style="display: flex; margin-left: -.5em" class="p-mt-5">

      <div class="p-field p-col-8 p-md-3" style="min-width: 12em">
        <label for="Start-datepicker"><b>Start</b></label>
        <br>
        <Calendar id="Start-datepicker"
                  v-model="startDate"
                  :showTime="true"
                  :hideOnDateTimeSelect="true"
                  :minDate="currentDate"
                  @dateSelect="validateData"
        >
          <template #header>Start</template>
        </Calendar>
        <InlineMessage v-if="validationErrors.start">{{validationErrors.start}}</InlineMessage>
        <small id="Start-datepicker-help">Når starter arrangementet?</small>
      </div>

      <div class="p-field p-col-8 p-md-3" style="min-width: 12em">
        <label for="Slutt-datepicker"><b>Slutt</b></label>
        <br>
        <Calendar id="Slutt-datepicker"
                  v-model="endDate"
                  :showTime="true"
                  :minDate="startDate"
                  :hideOnDateTimeSelect="true"
                  @dateSelect="validateData"
        >
          <template #header>Slutt</template>
        </Calendar>
        <InlineMessage v-if="validationErrors.end">{{validationErrors.end}}</InlineMessage>
        <small id="Slutt-datepicker-help">Når slutter arrangementet?</small>
      </div>
    </div>

    <br>
    <div style="text-align: center">
      <Button v-if="createMode" style="max-width: 15em" @click="onCreate" label="Fullfør" />
      <Button v-else @click="onSave" label="Oppdater" />
    </div>
  </form>
</template>


<script lang="ts">
import {computed, defineComponent, reactive, ref} from 'vue'
import FormInput from "@/components/FormComponents/FormInput.vue";
import {Category, Listing, Organization} from "@/interfaces/types";
import FormTextArea from "@/components/FormComponents/FormTextArea.vue";
import Button from "primevue/button/Button";
import InputNumber from 'primevue/inputnumber/InputNumber';
import AddressAutocomplete from "@/components/FormComponents/AddressAutocomplete.vue";
import RadioButton from "primevue/radiobutton/RadioButton";
import Calendar from "primevue/calendar/Calendar";
import Dropdown from "primevue/dropdown/Dropdown";
import {getLocations} from "@/composables/categoryStore";

export default defineComponent({
  name: "EventEditForm",
  props: {
    org: {
      type: Object as () => Organization,
      required: true,
    },
    listing: {
      required: true,
      type: Object as () => Listing,
    },
    createMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Calendar,
    Dropdown,
    AddressAutocomplete,
    FormTextArea,
    FormInput,
    Button,
    InputNumber,
    RadioButton,
  },

  setup(props, {emit}) {

    const listing = props.listing as Listing
    const isOnline = ref<boolean>(props.listing.isOnline || false)
    const registrationType = ref('none')
    const currentDate = ref<Date>(new Date())
    const startDate = ref<Date|null>(listing.startTime ? new Date(listing.startTime) : null)
    const endDate = ref<Date|null>(listing.endTime ? new Date(listing.endTime) : null)
    const orgLocations = ref(Array<Category>())
    const selectedLocation = ref<Category|null>(null)

    if (props.listing.externalRegistrationUrl) registrationType.value = 'external'
    if (props.listing.hasRegistration) registrationType.value = 'internal'

    const validationErrors = reactive({
      title: '',
      description: '',
      address: '',
      start: '',
      end: '',
      location: '',
      externalRegistrationUrl: '',
    })

    const updatedEvent = ref<Listing>( {
      ...listing,
    })

    function getLocationId() {
      if (props.org.locationIds.length === 1) return props.org.locationIds[0] || null
      const locationId = selectedLocation.value?.id
      return locationId || null
    }

    const validateData = (field?: string) => {
      if (!field || field === 'title') validationErrors.title = updatedEvent.value.title ? '' : 'Tittel kan ikke være tom.'
      if (!field || field === 'description') validationErrors.description = updatedEvent.value.description ? '' : 'Beskrivelsen kan ikke være tom.'
      if (!field || field === 'start') validationErrors.start = startDate.value ? '' : 'Start-tid mangler.'
      if (!field || field === 'end') {
        if (!endDate.value) validationErrors.end = 'Slutt-tid mangler.'
        else if (startDate.value && (endDate.value < startDate.value || 0)) validationErrors.end = 'Slutt-tid må være etter start-tid.'
        else validationErrors.end = ''
      }
      if (!field || field === 'address') validationErrors.address = isOnline.value || updatedEvent.value.address ? '' : 'Adresse mangler'
      if (!field || field === 'location') validationErrors.location = getLocationId() || updatedEvent.value.locationId ? '' : 'Sted mangler'
      return !(validationErrors.title || validationErrors.description || validationErrors.start || validationErrors.end || validationErrors.location);
    }


    const onSave = () => {
      if (!validateData()) return
      updatedEvent.value.startTime = startDate.value?.getTime()
      updatedEvent.value.endTime = endDate.value?.getTime()
      updatedEvent.value.isOnline = isOnline.value
      const locationId = getLocationId()
      if (locationId) updatedEvent.value.locationId = locationId
      updatedEvent.value.hasRegistration = registrationType.value === 'internal';
      if (registrationType.value !== 'external') {
        updatedEvent.value.externalRegistrationUrl = undefined
      }
      emit('save-event', updatedEvent.value)
    }

    const onCreate = () => {
      if (!validateData()) return
      updatedEvent.value.startTime = startDate.value?.getTime()
      updatedEvent.value.endTime = endDate.value?.getTime()
      updatedEvent.value.isOnline = isOnline.value
      const locationId = getLocationId()
      if (locationId) updatedEvent.value.locationId = locationId
      updatedEvent.value.hasRegistration = registrationType.value === 'internal';
      if (registrationType.value !== 'external') {
        updatedEvent.value.externalRegistrationUrl = undefined
      }
      emit('create-event', updatedEvent.value)
    }

    const loadLocations = async () => {
      orgLocations.value = await getLocations(props.org.locationIds)
      if (listing.locationId.length > 0) {
        selectedLocation.value = orgLocations.value.find(l => l.id === listing.locationId) || null
      }
    }

    return {
      event: updatedEvent,
      isOnline,
      registrationType,
      currentDate,
      startDate,
      endDate,
      validationErrors,
      orgLocations,
      selectedLocation,
      onSave,
      onCreate,
      validateData,
      loadLocations,
    }
  },
  mounted() {
    this.loadLocations()
  },
})

</script>

<style scoped>

</style>
