<template>
<div class="p-mx-6">
  <SubmitFormView v-if="event" :submit-state="unregisterState" @close="onCloseButton">
    <h3 class="p-mt-5">Vil du melde deg av {{event.title}}?</h3>
    <Button label="Meld av" @click="unregister" />
  </SubmitFormView>
  <EventLoadingView :timeout="5000" v-else style="text-align: left">
    <h2>Registrering ikke funnet</h2>
    <p>Vi finner ingen spor av denne registreringen. Hvis du nylig har meldt deg av arrangementet, betyr dette at du er avmeldt, og alle spor av registreringen er slettet.</p>
    <p>Hvis du ikke har meldt deg av enda, prøv å åpne avmeldings-lenken du fikk på SMS direkte, og forsikre deg om at du har tilgang til internett.</p>
  </EventLoadingView>
</div>
</template>

<script lang="ts">
import {defineComponent, reactive, ref} from 'vue'
import Button from "primevue/button/Button";
import * as api from "@/composables/api-open";
import {getEvent} from "@/composables/eventStore";
import SubmitFormView from "@/components/FormComponents/SubmitFormView.vue";
import {Listing} from "@/interfaces/types";
import router from "@/client-app/router";
import EventLoadingView from "@/components/Events/EventLoadingView.vue";

export default defineComponent({
  name: "LeaveEventPage",
  components: {
    EventLoadingView,
    SubmitFormView,
    Button,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const unregisterState = reactive({
      open: true,
      state: '',
      status: '',
    })

    const event = ref<Listing|null>(null)

    const unregister = async () => {
      console.log('Unregister with key ' + props.id)
      unregisterState.state = 'submitting'
      unregisterState.status = `Melder deg av...`
      const result = await api.unregisterFromEvent(props.id)
      const eventId = result.eventId
      if (eventId) {
        const event = await getEvent(eventId)
        unregisterState.state = 'submitted'
        unregisterState.status = `Du er nå meldt av ${event ? event.title : eventId}`
      } else {
        unregisterState.state = 'error'
        unregisterState.status = 'Noe gikk galt...'
      }
    }

    const loadData = async () => {
      unregisterState.state = ''
      unregisterState.status = ''
      const eventId = await api.getEventIdForUnregisterKey(props.id)
      if (eventId) {
      event.value = await getEvent(eventId)
      }
      else {
        event.value = null
      }
    }

    const onCloseButton = async () => {
      await router.push('/')
    }

    return {
      unregisterState,
      event,
      unregister,
      loadData,
      onCloseButton,
    }
  },
  mounted() {
    this.loadData()
  },
})

</script>

<style scoped>

</style>