
import {defineComponent, reactive, ref} from 'vue'
import Button from "primevue/button/Button";
import * as api from "@/composables/api-open";
import {getEvent} from "@/composables/eventStore";
import SubmitFormView from "@/components/FormComponents/SubmitFormView.vue";
import {Listing} from "@/interfaces/types";
import router from "@/client-app/router";
import EventLoadingView from "@/components/Events/EventLoadingView.vue";

export default defineComponent({
  name: "LeaveEventPage",
  components: {
    EventLoadingView,
    SubmitFormView,
    Button,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const unregisterState = reactive({
      open: true,
      state: '',
      status: '',
    })

    const event = ref<Listing|null>(null)

    const unregister = async () => {
      console.log('Unregister with key ' + props.id)
      unregisterState.state = 'submitting'
      unregisterState.status = `Melder deg av...`
      const result = await api.unregisterFromEvent(props.id)
      const eventId = result.eventId
      if (eventId) {
        const event = await getEvent(eventId)
        unregisterState.state = 'submitted'
        unregisterState.status = `Du er nå meldt av ${event ? event.title : eventId}`
      } else {
        unregisterState.state = 'error'
        unregisterState.status = 'Noe gikk galt...'
      }
    }

    const loadData = async () => {
      unregisterState.state = ''
      unregisterState.status = ''
      const eventId = await api.getEventIdForUnregisterKey(props.id)
      if (eventId) {
      event.value = await getEvent(eventId)
      }
      else {
        event.value = null
      }
    }

    const onCloseButton = async () => {
      await router.push('/')
    }

    return {
      unregisterState,
      event,
      unregister,
      loadData,
      onCloseButton,
    }
  },
  mounted() {
    this.loadData()
  },
})

