
import {defineComponent, ref, watch} from 'vue'
import {activeOrganizations, currentOrg} from "@/composables/orgStore";
import TabMenu from "primevue/tabmenu/TabMenu";

function isLoggedIn() {
  return !!currentOrg.value
}

function generateMenuItems() {
  const org = currentOrg.value
  if (org) {
    return [
    {label: 'Våre arrangementer', icon: 'pi pi-fw pi-calendar', to: `/org/${org.id || ''}/events`},
    {label: 'Innboks', icon: 'pi pi-fw pi-inbox', to: `/org/${org.id || ''}/messages`},
    {label: 'Profil', icon: 'pi pi-fw pi-id-card', to: `/org/${org.id || ''}/profile`},
    {label: `Logget inn som ${org.name}`, icon: 'pi pi-fw pi-lock', to: '/login'},
  ]
  } else {
    return [
      {label: 'Alle arrangementer', icon: 'pi pi-fw pi-clone', to: `/events` },
      {label: 'Logg inn', icon: 'pi pi-fw pi-lock', to: '/login'},

    ]
  }
}

export default defineComponent({
  name: 'App',
  components: {
    TabMenu,
  },
  data() {
    return {
      myOrgIds: Array<string>(),
    }
  },
  setup () {
    const menuItems = ref(generateMenuItems())

    watch(currentOrg, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        menuItems.value = generateMenuItems()
      }
    })

    return {
      menuItems,
      orgs: activeOrganizations,
      isLoggedIn,
    }
  },
})



