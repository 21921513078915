<template>
  <div id="nav">
    <TabMenu :model="menuItems" style="font-size: small"/>
  </div>
  <router-view/>
</template>
<script lang="ts">
import {defineComponent, ref, watch} from 'vue'
import {activeOrganizations, currentOrg} from "@/composables/orgStore";
import TabMenu from "primevue/tabmenu/TabMenu";

function isLoggedIn() {
  return !!currentOrg.value
}

function generateMenuItems() {
  const org = currentOrg.value
  if (org) {
    return [
    {label: 'Våre arrangementer', icon: 'pi pi-fw pi-calendar', to: `/org/${org.id || ''}/events`},
    {label: 'Innboks', icon: 'pi pi-fw pi-inbox', to: `/org/${org.id || ''}/messages`},
    {label: 'Profil', icon: 'pi pi-fw pi-id-card', to: `/org/${org.id || ''}/profile`},
    {label: `Logget inn som ${org.name}`, icon: 'pi pi-fw pi-lock', to: '/login'},
  ]
  } else {
    return [
      {label: 'Alle arrangementer', icon: 'pi pi-fw pi-clone', to: `/events` },
      {label: 'Logg inn', icon: 'pi pi-fw pi-lock', to: '/login'},

    ]
  }
}

export default defineComponent({
  name: 'App',
  components: {
    TabMenu,
  },
  data() {
    return {
      myOrgIds: Array<string>(),
    }
  },
  setup () {
    const menuItems = ref(generateMenuItems())

    watch(currentOrg, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        menuItems.value = generateMenuItems()
      }
    })

    return {
      menuItems,
      orgs: activeOrganizations,
      isLoggedIn,
    }
  },
})



</script>
<style lang="scss">
@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
   url(./fonts/Euclid/EuclidCircularA-Regular.otf) format("truetype");
}

#app {
  font-family: "Euclid", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 0;
  height: 10vh;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.p-menuitem-link {
  border-color: transparent !important;
}

.p-highlight > a {
  color: #00B8CC !important;
  border-color: #00B8CC !important;
}

.color-button-cyan {
  border-radius: 10px;
  width: 15em;
  height: 3em;
  background-color: #00B8CC;
  color: white;
  border: none;
  font-size: larger;
  margin: 1em;
}

.color-button-cyan:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.color-button-red {
  border-radius: 10px;
  width: 15em;
  height: 3em;
  background-color: #ff765b;
  color: white;
  border: none;
  font-size: larger;
  margin: 1em;
}

.color-button-red:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}

/* From PrimeVue */
.card {
  background: var(--surface-e);
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
}
</style>
