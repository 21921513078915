<template>
  <router-view/>
</template>
<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'App',
  components: {
  },
})



</script>
<style lang="scss">
@font-face {
  font-family: "Euclid";
  src: local("Euclid"),
   url(./fonts/Euclid/EuclidCircularA-Regular.otf) format("truetype");
}

#app {
  font-family: "Euclid", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: -0px !important;
}

body { 
  margin: 0 !important; 
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.color-button-cyan {
  border-radius: 10px;
  width: 15em;
  height: 3em;
  background-color: #00B8CC;
  color: white;
  border: none;
  font-size: larger;
  margin: 1em;
}

.color-button-cyan:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

.color-button-red {
  border-radius: 10px;
  width: 15em;
  height: 3em;
  background-color: #ff765b;
  color: white;
  border: none;
  font-size: larger;
  margin: 1em;
}

.color-button-red:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: black;
}

/* selected link */
a:active {
  color: black;
}

/* From PrimeVue */
.p-progressbar {
  background-color: #F6F7F8;
}
.p-progressbar-value {
  background-color: #00B8CC !important;
}
.p-component {
  font-family: "Euclid", Avenir, Helvetica, Arial, sans-serif;
}
.p-button {
  background-color: #00B8CC;
  border: none;
}
.p-togglebutton.p-button.p-highlight {
  background-color: #00B8CC !important;
  border-color: #00B8CC !important;
}

.p-selectbutton .p-button.p-highlight {
  background-color: #00B8CC !important;
  border-color: #00B8CC !important;
}
.card {
  background: var(--surface-e);
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

</style>
