<template>
  <div style="text-align: left">
    <h2>{{org.name}}</h2>
    <div v-for="location in locations" :key="location.id" class="p-my-3">
      <img alt="lokasjon" class="icon" src="@/assets/icons/location.svg"><span>{{location.label}}</span>
    </div>
    <div style="font-size: small; white-space: pre-wrap;">
      {{org.description}}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {Category, Organization} from "@/interfaces/types";

export default defineComponent({
  name: "OrgInfo",
  props: {
    org: {
      type: Object as () => Organization,
      required: true,
    },
    locations: {
      type: Object as () => Array<Category>,
      required: true,
    },
  },
})

</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
</style>
